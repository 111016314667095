<template>
  <v-card
    flat
    class="pt-0 px-4"
  >
    <v-toolbar
      v-if="hasToolbar"
      flat
      class="general-form-toolbar"
      width="100%"
      :rounded="false"
    >
      <v-toolbar-title>{{ $t('profile|individual_profile') }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        rounded
        @click="$emit('close')"
        @mouseover="hoverCloseButtonIcon = true"
        @mouseleave="hoverCloseButtonIcon = false"
      >
        <v-icon
          :small="hoverCloseButtonIcon"
        >
          mdi-close
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-title
      v-if="!hasToolbar"
      class="d-flex justify-space-between"
    >
      {{ $t('clients|individual') }}
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="form.given_names"
        :label="$t('clients|first_name')"
        :readonly="client.realClient"
        outlined
        dense
        class="mt-2"
      />
      <v-text-field
        v-model="form.surname"
        :label="$t('clients|surname')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-autocomplete
        v-model="form.nationality"
        :items="CountryList"
        :filter="countryFilter"
        item-text="nationality"
        item-value="alpha_2_code"
        :label="$t('clients|nationality')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.place_of_birth"
        :label="$t('clients|place_of_birth')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-menu
        v-model="dateOfBirthMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.date_of_birth"
            :label="$t('clients|date_of_birth') + ' ' + $t('common|in_format')"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-if="!client.realClient"
          v-model="date"
          @input="dateOfBirthMenu = false"
        />
      </v-menu>
      <v-text-field
        v-model="form.address"
        :label="$t('clients|address')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.city"
        :label="$t('clients|city')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.postal_code"
        :label="$t('clients|postal_code')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.email"
        :label="$t('common|email')"
        :readonly="client.realClient"
        outlined
        dense
      />
    </v-card-text>

    <v-card-actions
      class="mx-n4"
      style="position: sticky; bottom: 10px;"
    >
      <v-toolbar
        flat
        dense
        class="mb-n5"
      >
        <v-btn
          v-if="hasBackButton"
          text
          rounded
          class="mb-2"
          @click="getBack"
        >
          <v-icon
            class="mr-1"
          >
            mdi-undo
          </v-icon>
          {{ $t('actions|back') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!client.realClient"
          color="primary"
          class="mb-2"
          rounded
          dense
          small
          :loading="clientsLoading || accountLoading"
          min-width="150"
          @click="submit"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </v-toolbar>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CountryList from '@/enums/CountryList.json'

const formData = {
  given_names: null,
  surname: null,
  email: null,
  nationality: null,
  place_of_birth: null,
  date_of_birth: null,
  address: null
}

export default {
  props: {
    client: {
      type: Object,
      default: () => ({
        files: []
      })
    },
    hasFileUploader: {
      type: Boolean,
      default: true
    },
    hasToolbar: {
      type: Boolean,
      default: true
    },
    hasBackButton: {
      type: Boolean,
      default: true
    },
    actualTab: {
      type: Object,
      default: null
    },
    clientsLoading: {
      type: Boolean,
      default: false
    },
    accountLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    let form
    if (this.client && this.client.clientData) form = this.client.clientData
    else form = { ...formData }
    return {
      dateOfBirthMenu: false,
      date: null,
      CountryList,
      loading: false,
      error: null,
      form,
      hoverCloseButtonIcon: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    })
  },
  watch: {
    date (val) {
      if (val) {
        this.formatDate(val)
      }
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast'
    }),
    formatDate (date) {
      const [year, month, day] = date.split('-')
      this.form.date_of_birth = `${day}/${month}/${year}`
    },
    countryFilter (item, queryText, itemText) {
      const textOne = item.nationality.toLowerCase()
      const textTwo = item.en_short_name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },
    reset () {
      this.form = { ...formData }
    },
    async submit () {
      this.$emit('submit', {
        form: this.form,
        files: this.client.documents,
        actualTab: this.actualTab
      })
    },
    getBack () {
      this.$emit('get-back')
    }
  }
}
</script>

<style lang="scss" scoped>
.general-form-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
