<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    keydown
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('questionnaire|questionnaire') }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-4">
        <v-row v-if="payload && payload.questionnaire">
          <v-col
            v-for="(field, key) in payload.questionnaire"
            :key="key"
            cols="12"
          >
            <component
              :is="field.fieldType"
              v-model="field.value"
              :label="field.name"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
        >
          {{ $t('actions|close') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          large
          min-width="150"
          :loading="loading"
          @click="update"
        >
          {{ $t('actions|save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      error: null,
      payload: null
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    }
  },
  created () {
    EventBus.$on('dialog-view-questionairre', this.onOpen)
  },
  beforeDestroy () {
    EventBus.$off('dialog-view-questionairre', this.onOpen)
  },
  methods: {
    ...mapActions({
      updateCaseWorkbenchTemplate: 'cases/updateCaseWorkbenchTemplate'
    }),
    onOpen (payload) {
      this.dialog = true
      this.payload = payload
    },
    reset () {
      this.payload = null
    },
    async update () {
      this.loading = true
      try {
        const { _id, workbenchId, templateId, questionnaire } = this.payload
        await this.updateCaseWorkbenchTemplate({
          _id,
          workbenchId,
          templateId,
          payload: {
            questionnaire
          }
        })
        this.dialog = false
        this.reset()
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
