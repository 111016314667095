<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    keydown
    width="800px"
    transition="dialog-bottom-transition"
  >
    <v-container
      style="background-color: white;"
    >
      <v-card
        flat
      >
        <v-toolbar
          flat
          class="general-form-toolbar"
          width="100%"
          :rounded="false"
        >
          <v-toolbar-title>{{ $t('cases|new_case') }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <div
              style="position: absolute; top: 3px; right: 7px"
            >
              <v-btn
                icon
                rounded
                @click="dialog = false"
                @mouseover="hoverCloseButtonIcon = true"
                @mouseleave="hoverCloseButtonIcon = false"
              >
                <v-icon
                  :small="hoverCloseButtonIcon"
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </v-toolbar-items>
        </v-toolbar>
        <div
          v-if="account && account.accountType === 'lawyer'"
        >
          <v-stepper
            v-model="e6"
            vertical
            flat
          >
            <v-stepper-step
              :complete="e6 > 0"
              step="1"
            >
              {{ $t('actions|add_to') }}
              <small
                v-if="selectedCasesSection"
                class="mt-1"
              >
                {{ selectedCasesSection }}
              </small>
            </v-stepper-step>

            <v-stepper-content
              step="0"
            >
              <v-select
                v-model="selectedCasesSection"
                :items="availableCasesSections"
                outlined
                dense
                item-text="text"
                item-value="text"
                item-disabled="disabled"
                :label="$t('cases|case_is_intended_for')"
                class="my-2"
                @change="e6 = 1"
              />
            </v-stepper-content>

            <v-stepper-step
              :complete="e6 > 1"
              :step="2"
            >
              {{ $t('actions|select_client') }}
              <small
                v-if="selectedClient"
                class="mt-1"
              >
                {{ selectedClient.displayName }}
              </small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-list
                flat
              >
                <v-subheader>{{ $t('clients|available_clients_for') }}: {{ selectedCasesSection }}</v-subheader>
                <v-list-item-group
                  v-model="selectedClient"
                  color="primary"
                >
                  <v-list-item
                    v-for="(client, i) in getItems"
                    :key="i"
                    :value="client"
                    @click="e6++"
                  >
                    <v-list-item-icon>
                      <v-icon
                        color="primary"
                        small
                      >
                        mdi-account
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="client.displayName" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-subheader v-if="!getItems.length">
                  {{ $t('clients|no_actual_clients_available') }}. {{ $t('expressions|please_add_client_to_create_case') }}.
                </v-subheader>
              </v-list>
              <v-btn
                text
                @click="e6--; selectedCasesSection = null"
              >
                <v-icon
                  class="mr-1"
                >
                  mdi-undo
                </v-icon>
                {{ $t('actions|back') }}
              </v-btn>
            </v-stepper-content>

            <v-stepper-step
              :complete="e6 > 2"
              step="3"
            >
              {{ $t('cases|case_name') }}
              <small
                v-if="caseName"
                class="mt-1"
              >
                {{ caseName }}
              </small>
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-text-field
                v-if="account && account.accountType === 'lawyer'"
                v-model="caseName"
                outlined
                dense
                clearable
                :label="$t('cases|enter_name')"
                class="mx-4 mt-2"
              />
              <v-btn
                text
                class="ml-2"
                @click="e6--"
              >
                <v-icon
                  class="mr-1"
                >
                  mdi-undo
                </v-icon>
                {{ $t('actions|back') }}
              </v-btn>
              <v-btn
                color="primary"
                rounded
                small
                min-width="100"
                :loading="loading"
                :disabled="disableSubmit"
                style="position: absolute; right: 40px;"
                @click="e6 = 3; submit()"
              >
                {{ $t('actions|submit') }}
              </v-btn>
            </v-stepper-content>
          </v-stepper>
        </div>

        <div v-else>
          <v-select
            v-if="account && (account.accountType === 'company' || account.accountType === 'individual')"
            v-model="selectedProfessional"
            :items="myProfessionals"
            :label="$t('actions|choose_professional')"
            outlined
            dense
            required
            item-text="title"
            item-value="value"
            class="mx-4 mt-4"
          />
          <v-expand-transition>
            <v-container
              v-if="selectedProfessional"
              fill-height
              fluid
            >
              <v-card
                flat
                outlined
                width="100%"
                class="pa-4 mx-1"
              >
                <v-card-title
                  class="mt-n6 text-overline"
                >
                  {{ $t('actions|upload_documents') }}
                </v-card-title>
                <v-card-text>
                  <file-input
                    v-model="innerDocuments"
                    :has-upload-button="false"
                    :has-delete-button="false"
                    file-key="raw"
                    style="width: 100%"
                  />
                </v-card-text>
              </v-card>
            </v-container>
          </v-expand-transition>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              rounded
              small
              min-width="150"
              :loading="loading"
              class="mb-3"
              :disabled="disableSubmit"
              @click="submit()"
            >
              {{ $t('actions|submit') }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { userHasAccess } from '@/utils/utils'
import { mapState, mapActions } from 'vuex'
import generalMixin from '@/utils/generalMixin.js'
import LanguagesList from '@/enums/LanguagesList.json'
import cfg from '@/config'

export default {
  mixins: [generalMixin],
  data () {
    return {
      dialog: false,
      selectedClient: null,
      selectedCasesSection: null,
      selectedProfessional: null,
      caseName: null,
      languagesList: Object.keys(LanguagesList).filter((key) => {
        return cfg.usedLanguages.includes(key)
      }).map((key) => {
        return {
          value: key,
          title: LanguagesList[key].name
        }
      }),
      innerDocuments: [],
      loading: false,
      error: null,
      hoverCloseButtonIcon: false,
      actualTab: {},
      e6: 0
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      clients: state => state.clients.clients,
      companyClients: state => state.companyClients.companyClients,
      groupClients: state => state.groupClients.groupClients,
      meAsClient: state => state.clients.meAsClient,
      documents: state => state.documents.documents
    }),
    availableCasesSections () {
      let sections = []
      const getAccess = (_acc, _comp, _group, _tab, _from) => {
        return userHasAccess(_acc, _comp, _group, _tab, _from)
      }
      const accountAccess = getAccess(this.account, null, null, null, 'onlyAccountAccess')
      const companyAccess = getAccess(this.account, this.company, null, null, 'availableSections')

      if (this.account && !this.account.companyId) {
        sections.push({
          text: this.$t('cases|my_cases'),
          disabled: !accountAccess
        })
      }
      if (this.account && this.account.companyId && this.company) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole could be found in generalMixin
        if (userRole === 'regular') {
          sections.push({
            text: this.company.companyData.companyName + ' ' + this.$t('cases|cases'),
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              sections.push({
                text: group.groupName + ' ' + this.$t('cases|cases'),
                disabled: !companyAccess
              })
            }
          }
        } else {
          sections.push({
            text: this.$t('cases|my_cases'),
            disabled: !accountAccess
          })
          sections.push({
            text: this.company.companyData.companyName + ' ' + this.$t('cases|cases'),
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              sections.push({
                text: group.groupName + ' ' + this.$t('cases|cases'),
                disabled: !companyAccess
              })
            }
          }
        }
      }
      return sections
    },
    getItems () {
      let myAvailableClientSections = []
      if (this.selectedCasesSection && this.selectedCasesSection === this.$t('cases|my_cases')) {
        myAvailableClientSections = this.personalClientsList
      }
      if (this.selectedCasesSection && this.company && this.selectedCasesSection === this.company.companyData.companyName + ' ' + this.$t('cases|cases')) {
        myAvailableClientSections = this.companyClientsList
      }
      if (this.selectedCasesSection && this.selectedCasesSection !== this.$t('cases|my_cases') && this.company && this.selectedCasesSection !== this.company.companyData.companyName + ' ' + this.$t('cases|cases')) {
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          const extendedGroupName = group.groupName + ' ' + this.$t('cases|cases')
          if (extendedGroupName === this.selectedCasesSection) {
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              this.groupClientsList.forEach(client => {
                if (client.sharedWith.groups.includes(group._id)) {
                  myAvailableClientSections.push(client)
                }
              })
            }
          }
        }
      }
      return myAvailableClientSections
    },
    personalClientsList () {
      const clients = this.clients.map((client) => {
        let displayName
        if (client.clientType === 'individual') {
          if (client.clientData.given_names) {
            displayName = `${client.clientData.given_names} ${client.clientData.surname}`
          } else {
            displayName = 'Pending Name'
          }
        } else {
          if (client.clientData.company_name) {
            displayName = client.clientData.company_name
          } else {
            displayName = 'Pending Company Name'
          }
        }
        return {
          _id: client._id,
          displayName
        }
      })
      return clients
    },
    companyClientsList () {
      const clients = this.companyClients.map((client) => {
        let displayName
        if (client.clientType === 'individual') {
          if (client.clientData.given_names) {
            displayName = `${client.clientData.given_names} ${client.clientData.surname}`
          } else {
            displayName = 'Pending Name'
          }
        } else {
          if (client.clientData.company_name) {
            displayName = client.clientData.company_name
          } else {
            displayName = 'Pending Company Name'
          }
        }
        return {
          _id: client._id,
          displayName
        }
      })
      return clients
    },
    groupClientsList () {
      const clients = this.groupClients.map((client) => {
        let displayName
        if (client.clientType === 'individual') {
          if (client.clientData.given_names) {
            displayName = `${client.clientData.given_names} ${client.clientData.surname}`
          } else {
            displayName = 'Pending Name'
          }
        } else {
          if (client.clientData.company_name) {
            displayName = client.clientData.company_name
          } else {
            displayName = 'Pending Company Name'
          }
        }
        return {
          _id: client._id,
          displayName,
          sharedWith: client.sharedWith
        }
      })
      return clients
    },
    myProfessionals () {
      let professionalsArray = []
      if (this.account && this.account.myProfessionals) {
        this.account.myProfessionals.accounts.forEach(pro => {
          const firstName = pro.accountData && pro.accountData.firstName ? pro.accountData.firstName : ''
          const lastName = pro.accountData && pro.accountData.lastName ? pro.accountData.lastName : ''
          const proName = firstName + ' ' + lastName
          const email = pro.email
          const myPro = {
            title: proName.length ? proName : email,
            value: pro
          }
          professionalsArray.push(myPro)
        })
        this.account.myProfessionals.company.forEach(company => {
          const myPro = {
            title: company.companyData.companyName,
            value: company
          }
          professionalsArray.push(myPro)
        })
        this.account.myProfessionals.groups.forEach(group => {
          const myPro = {
            title: group.groupName,
            value: group
          }
          professionalsArray.push(myPro)
        })
      }
      return professionalsArray
    },
    disableSubmit () {
      let rule
      if (this.account && this.account.accountType === 'lawyer') {
        if (this.selectedClient === null || this.caseName === null || !this.caseName.length) {
          rule = true
        }
      } else if (this.account && (this.account.accountType === 'company' || this.account.accountType === 'individual')) {
        if (this.selectedProfessional === null || !this.innerDocuments.length) {
          rule = true
        } else if (this.innerDocuments.length) {
          this.innerDocuments.forEach(doc => {
            if (doc.language.length) {
              rule = false
            } else {
              rule = true
            }
          })
        }
      }
      return rule
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    },
    selectedCasesSection (value) {
      if (value) {
        if (this.selectedCasesSection && this.selectedCasesSection === this.$t('cases|my_cases')) {
          this.actualTab = {
            isMyTab: true
          }
        }
        if (this.selectedCasesSection && this.company && this.selectedCasesSection === this.company.companyData.companyName + ' ' + this.$t('cases|cases')) {
          this.actualTab = {
            isCompanyTab: true
          }
        }
        if (this.selectedCasesSection && this.selectedCasesSection !== this.$t('cases|my_cases') && this.company && this.selectedCasesSection !== this.company.companyData.companyName + ' ' + this.$t('cases|cases')) {
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const extendedGroupName = group.groupName + ' ' + this.$t('cases|cases')
            if (extendedGroupName === this.selectedCasesSection) {
              this.actualTab = {
                isGroupTab: true,
                group: group
              }
            }
          }
        }
      }
    }
  },
  created () {
    EventBus.$on('dialog-add-case', this.onAddCaseEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-add-case', this.onAddCaseEvent)
  },
  methods: {
    ...mapActions({
      addCase: 'cases/addCase',
      addCompanyCase: 'companyCases/addCompanyCase',
      addGroupCase: 'groupCases/addGroupCase',
      uploadDocument: 'documents/uploadDocument'
    }),
    onAddCaseEvent (emitedTab) {
      if (emitedTab.isCompanyTab) {
        this.selectedCasesSection = emitedTab.company.companyData.companyName + ' ' + this.$t('cases|cases')
        this.e6 = 1
      }
      if (emitedTab.isGroupTab) {
        this.selectedCasesSection = emitedTab.group.groupName + ' ' + this.$t('cases|cases')
        this.e6 = 1
      }
      if (emitedTab.isMyTab) {
        this.selectedCasesSection = this.$t('cases|my_cases')
        this.e6 = 1
      }
      this.dialog = true
      this.actualTab = emitedTab
    },
    reset () {
      this.e6 = 0
      this.selectedClient = null
      this.selectedCasesSection = null
      this.caseName = null
      this.selectedProfessional = null
      this.actualTab = {}
      this.innerDocuments = []
    },
    async submit () {
      this.loading = true
      if (this.actualTab.isMyTab && (this.account.accountType !== 'individual' && this.account.accountType !== 'company')) {
        const payload = {
          clientId: this.selectedClient._id,
          caseName: this.caseName,
          isMyTab: true
        }
        try {
          await this.addCase(payload)
          this.dialog = false
          this.reset()
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      }
      if (this.actualTab.isCompanyTab) {
        const payload = {
          clientId: this.selectedClient,
          caseName: this.caseName,
          isCompanyTab: true
        }
        try {
          await this.addCompanyCase(payload)
          this.dialog = false
          this.reset()
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      }
      if (this.actualTab.isGroupTab) {
        const payload = {
          clientId: this.selectedClient,
          caseName: this.caseName,
          isGroupTab: true,
          groupId: this.actualTab.group._id
        }
        try {
          await this.addGroupCase(payload)
          this.dialog = false
          this.reset()
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      }
      if (
        this.actualTab &&
        (this.account.accountType === 'company' || this.account.accountType === 'individual') &&
        this.innerDocuments.length
      ) {
        let docsToBeAssignedToCase = []
        for (let i = 0; i < this.innerDocuments.length; i++) {
          const document = this.innerDocuments[i]
          let payload = {}
          const _id = this.account._id
          payload = {
            fromClient: true,
            language: document.language,
            clients: this.account._id,
            professionalId: this.selectedProfessional._id,
            file: document.file
          }
          const tempDoc = await this.uploadDocument({ _id, payload })
          docsToBeAssignedToCase.push(tempDoc.data._id)
        }
        let foundClientId
        for (let i = 0; i < this.meAsClient.length; i++) {
          const client = this.meAsClient[i]
          const isActualClient = client.sharedWith.accounts.find(id => id === this.selectedProfessional._id) ||
          client.sharedWith.company.find(id => id === this.selectedProfessional._id) ||
          client.sharedWith.groups.find(id => id === this.selectedProfessional._id)
          if (isActualClient) {
            foundClientId = client._id
            break
          }
        }
        const payload = {
          clientId: foundClientId,
          fromClient: true,
          professionalId: this.selectedProfessional._id,
          professionalType: this.selectedProfessional.companyData ? 'Company' : this.selectedProfessional.accountData ? 'Account' : 'Group',
          documents: docsToBeAssignedToCase
        }
        try {
          await this.addCase(payload)
          this.dialog = false
          this.reset()
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.general-form {
  position: relative;
  display: flex;
  flex-direction: column;
}
.general-form-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 50;
}
</style>
