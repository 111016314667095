<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <v-card>
      <v-toolbar
        flat
        class="fixed-bar"
      >
        <span class="headline">{{ $t('actions|add_document') }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-stepper
        v-if="fromCaseView"
        v-model="stepper"
        vertical
      >
        <v-stepper-step
          step="1"
          complete
        >
          {{ $t('actions|choose_source') }}
          <small
            v-if="stepper > 1"
            class="mt-2"
          >
            {{ selectedItem === 'Documents' ? $t('documents|available_documents') : $t('actions|upload') }}
          </small>
        </v-stepper-step>

        <v-stepper-content
          step="1"
          class="text-center"
        >
          <v-radio-group v-model="selectedItem">
            <v-row
              class="text-center"
            >
              <v-col
                cols="12"
              >
                <v-card
                  class="d-inline-flex align-center pa-2 my-2"
                  outlined
                  rounded
                  flat
                  height="40"
                  width="300"
                >
                  <v-row
                    class="ml-2 pt-2"
                  >
                    <v-radio
                      :label="$t('actions|upload_from_computer')"
                      color="primary"
                      value="upload"
                    />
                    <v-icon
                      style="position: absolute; right: 5%;"
                      color="primary"
                      right
                    >
                      mdi-arrow-up-bold
                    </v-icon>
                  </v-row>
                </v-card>

                <v-card
                  class="d-inline-flex align-center pa-2 my-2"
                  outlined
                  rounded
                  flat
                  height="40"
                  width="300"
                >
                  <v-row class="pt-2 ml-2">
                    <v-radio
                      :label="$t('documents|available_documents')"
                      color="primary"
                      value="Documents"
                    />
                    <v-icon
                      style="position: absolute; right: 5%;"
                      color="primary"
                      right
                    >
                      mdi-file
                    </v-icon>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-stepper-content>

        <v-stepper-step
          step="2"
          complete
        >
          {{ $t('actions|select_document') }}
        </v-stepper-step>

        <v-stepper-content
          step="2"
        >
          <div
            v-if="fromAvailableDocuments"
            class="pr-4"
          >
            <v-select
              v-if="userRole && userRole !== 'singleAcc'"
              v-model="selectedSource"
              :items="sources"
              :label="$t('actions|choose_section')"
              dense
              multiple
              :prepend-inner-icon="icons.select"
              style="height: 80%; font-size: 0.8em"
              class="mx-4 mt-8 mb-n3"
            />
            <document-list
              :documents="filteredPersonalDocuments"
              :company-documents="filteredCompanyDocuments"
              :group-documents="filteredGroupDocuments"
              :personal-folders="personalFolders"
              :company-folders="companyFolders"
              :group-folders="groupFolders"
              :list-group="selectedDocuments"
              :list-group-company="selectedCompanyDocuments"
              :selected-group-documents="selectedGroupDocuments"
              :toolbar="{
                hasClose: false
              }"
              :from-case-view="fromCaseView"
              :is-flat="true"
              :selected-source="selectedSource"
              :company="company"
              :available-groups="availableGroups"
              :from-workbench="fromWorkbench"
              class="flex-grow-1 pt-5"
              @updateGroup="updateGroup"
              @updateCompanyDocs="updateSelectedCompanyDocuments"
              @updateGroupDocs="updateSelectedGroupDocuments"
              @search="setInput"
              @toolbarClose="dialog = false"
              @selectedFolderDocuments="onSelectedFolderDocuments"
            >
              <template slot="actions">
                <v-btn
                  text
                  class="ml-2"
                  @click="getBackInStepper"
                >
                  <v-icon class="mr-2">
                    mdi-undo
                  </v-icon>
                  {{ $t('actions|back') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  rounded
                  color="primary"
                  small
                  min-width="150px"
                  @click="selectDocumentAction"
                >
                  {{ $t('actions|submit') }}
                </v-btn>
              </template>
            </document-list>
          </div>
        </v-stepper-content>

        <v-stepper-content
          step="3"
        >
          <file-input
            v-model="innerDocuments"
            class="mt-6 mx-6"
            file-key="raw"
          />
          <v-btn
            text
            class="mt-3 ml-2"
            @click="getBackInStepper"
          >
            <v-icon class="mr-2">
              mdi-undo
            </v-icon>
            {{ $t('actions|back') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            right
            class="mt-2"
            style="position: absolute; right: 28px;"
            :disabled="!innerDocuments.length"
            @click="uploadDocumentAction"
          >
            {{ $t('actions|upload') }}
          </v-btn>
        </v-stepper-content>
      </v-stepper>
      <div v-else>
        <v-select
          v-if="userRole && userRole !== 'singleAcc'"
          v-model="selectedSource"
          :items="sources"
          label="Select source"
          dense
          multiple
          :prepend-inner-icon="icons.select"
          style="height: 80%; font-size: 0.8em"
          class="mx-4 mt-8 mb-n3"
        />
        <document-list
          :documents="filteredPersonalDocuments"
          :company-documents="filteredCompanyDocuments"
          :group-documents="filteredGroupDocuments"
          :list-group="selectedDocuments"
          :list-group-company="selectedCompanyDocuments"
          :selected-group-documents="selectedGroupDocuments"
          :toolbar="{
            hasClose: false
          }"
          :from-case-view="fromCaseView"
          :is-flat="true"
          :selected-source="selectedSource"
          :company="company"
          :available-groups="availableGroups"
          :from-workbench="fromWorkbench"
          class="flex-grow-1 pt-5"
          @updateGroup="updateGroup"
          @updateCompanyDocs="updateSelectedCompanyDocuments"
          @updateGroupDocs="updateSelectedGroupDocuments"
          @search="setInput"
          @toolbarClose="dialog = false"
        >
          <template slot="actions">
            <v-spacer />
            <v-btn
              rounded
              color="primary"
              small
              min-width="150px"
              @click="selectDocumentAction"
            >
              {{ $t('actions|submit') }}
            </v-btn>
          </template>
        </document-list>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { mdiSelectPlace } from '@mdi/js'

export default {
  data () {
    return {
      dialog: false,
      selectedDocuments: [],
      selectedCompanyDocuments: [],
      selectedGroupDocuments: [],
      personalDocuments: [],
      companyDocuments: [],
      groupDocuments: [],
      innerDocuments: [],
      selectedFolderDocuments: [],
      personalFolders: [],
      companyFolders: [],
      groupFolders: [],
      fromCaseView: false,
      actualCase: {},
      company: {},
      account: {},
      userRole: '',
      callback: null,
      searchResult: '',
      selectedSource: [].reverse(),
      icons: {
        select: mdiSelectPlace
      },
      availableGroups: [],
      fromWorkbench: false,
      stepper: 1,
      selectedItem: null,
      fromAvailableDocuments: false,
      activeFolderObject: null
    }
  },
  computed: {
    filteredPersonalDocuments () {
      if (this.searchResult && this.searchResult.length && this.personalDocuments.length) {
        return this.personalDocuments.filter((d) => {
          return d.raw.originalname.toLowerCase().includes(this.searchResult.toLowerCase())
        })
      }
      return this.personalDocuments
    },
    filteredCompanyDocuments () {
      if (this.searchResult && this.searchResult.length && this.companyDocuments.length) {
        return this.companyDocuments.filter((d) => {
          return d.raw.originalname.toLowerCase().includes(this.searchResult.toLowerCase())
        })
      }
      return this.companyDocuments
    },
    filteredGroupDocuments () {
      if (this.searchResult && this.searchResult.length && this.groupDocuments.length) {
        return this.groupDocuments.filter((d) => {
          return d.raw.originalname.toLowerCase().includes(this.searchResult.toLowerCase())
        })
      }
      return this.groupDocuments
    },
    sources () {
      let items = []
      if (this.personalDocuments.length && this.userRole !== 'regular') {
        items.push(this.$t('documents|my_documents'))
      }
      if (this.company) {
        items.push(this.company.companyData.companyName)
        if (this.company.groups) {
          this.company.groups.forEach(group => {
            const isPermitted = group.groupMembers.find(mem => mem._id === this.account._id)
            if (isPermitted) {
              items.push(group.groupName)
            }
          })
        }
      }
      return items
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.personalDocuments = []
        this.companyDocuments = []
        this.groupDocuments = []
        this.selectedDocuments = []
        this.selectedCompanyDocuments = []
        this.selectedGroupDocuments = []
        this.innerDocuments = []
        this.selectedSource = []
        this.fromCaseView = false
        this.actualCase = {}
        this.company = {}
        this.account = {}
        this.userRole = ''
        this.searchResult = ''
        this.availableGroups = []
        this.callback = null
        this.fromWorkbench = false
        this.stepper = 1
        this.selectedItem = null
      }
    },
    selectedItem (value) {
      if (value && value === 'upload') {
        this.stepper = 3
      }
      if (value && value === 'Documents') {
        this.stepper = 2
        this.fromAvailableDocuments = true
      }
    }
  },
  created () {
    EventBus.$on('dialog-documents', this.onOpen)
  },
  beforeDestroy () {
    EventBus.$off('dialog-documents', this.onOpen)
  },
  methods: {
    ...mapActions({
      uploadDocument: 'documents/uploadDocument'
    }),
    getBackInStepper () {
      this.stepper = 1
      this.selectedItem = null
    },
    updateGroup (index) {
      this.selectedDocuments = index
    },
    updateSelectedCompanyDocuments (index) {
      this.selectedCompanyDocuments = index
    },
    updateSelectedGroupDocuments (index) {
      this.selectedGroupDocuments = index
    },
    onOpen ({
      documents,
      fromWorkbench,
      companyDocuments,
      groupDocuments,
      fromCaseView,
      actualCase,
      userRole,
      company,
      account,
      callback,
      personalFolders,
      companyFolders,
      groupFolders
    } = {}) {
      if (!fromWorkbench) {
        this.dialog = true
        this.personalDocuments = documents || []
        this.companyDocuments = companyDocuments || []
        this.groupDocuments = groupDocuments || []
        this.personalFolders = personalFolders
        this.companyFolders = companyFolders
        this.groupFolders = groupFolders
        this.fromCaseView = fromCaseView || false
        this.actualCase = actualCase || {}
        this.userRole = userRole
        this.company = company
        this.account = account
        this.callback = callback

        if (this.personalDocuments.length && this.userRole !== 'regular' && documents.length) {
          this.selectedSource.push(this.$t('documents|my_documents'))
        }
        if (this.company) {
          if (this.companyDocuments.length) this.selectedSource.push(this.company.companyData.companyName)
          if (this.company.groups) {
            this.company.groups.forEach(group => {
              const isPermitted = group.groupMembers.find(mem => mem._id === this.account._id)
              if (isPermitted) {
                for (let index = 0; index < this.groupDocuments.length; index++) {
                  const doc = this.groupDocuments[index]
                  const groupHasDocs = doc.sharedWith.groups.findIndex(gr => gr._id === group._id)
                  if (groupHasDocs !== -1) {
                    this.selectedSource.push(group.groupName)
                    break
                  }
                }
                this.availableGroups.push(group.groupName)
              }
            })
          }
        }
      } else {
        this.dialog = true
        this.personalDocuments = documents || []
        this.fromWorkbench = fromWorkbench
        this.callback = callback
      }
    },
    selectDocumentAction () {
      let selectedDocuments = []
      this.personalDocuments.filter((doc, index) => {
        if (this.selectedDocuments.includes(index)) {
          selectedDocuments.push(doc)
        }
      })
      this.companyDocuments.filter((doc, index) => {
        if (this.selectedCompanyDocuments.includes(index)) {
          selectedDocuments.push(doc)
        }
      })
      this.groupDocuments.filter((doc, index) => {
        if (this.selectedGroupDocuments.includes(index)) {
          selectedDocuments.push(doc)
        }
      })
      if (this.activeFolderObject) selectedDocuments = this.selectedFolderDocuments
      this.callback(selectedDocuments)
      this.dialog = false
    },
    async uploadDocumentAction () {
      for (let i = 0; i < this.innerDocuments.length; i++) {
        const document = this.innerDocuments[i]
        const _id = this.account._id
        const payload = {
          language: document.language,
          clientId: this.actualCase.client._id,
          file: document.file,
          caseId: this.actualCase._id,
          newInCaseUploadedDocument: true,
          fromClient: this.actualCase.client.clientAccount ? this.actualCase.client.clientAccount._id === this.account._id : false
        }
        await this.uploadDocument({ _id, payload })
        this.dialog = false
      }
    },
    setInput (input) {
      this.searchResult = input
    },
    onSelectedFolderDocuments (selectedDocs, folder) {
      this.selectedFolderDocuments = selectedDocs
      this.activeFolderObject = folder
    }
  }
}
</script>

<style scoped>
.close-position {
  position: absolute;
  top: 5px;
  right: 5px;
}
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0em;
  z-index: 2;
}
</style>
