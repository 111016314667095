<template>
  <v-dialog
    v-model="value"
    scrollable
    max-width="800px"
  >
    <v-card v-if="workbench">
      <v-card-title>
        <div>General Questionnaire for workbench: {{ workbench.name }}</div>
        <v-spacer />
        <v-btn
          icon
          @click="$emit('input', !value)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div
          v-for="template in workbench.templates"
          :key="template._id"
        >
          <v-card-title>Template: {{ template.name }}</v-card-title>
          <v-card-text
            class="pt-4"
            v-html="template.questionnaire.title"
          />
          <v-card-text
            class="pt-4"
          >
            <QuestionnaireForm
              :questionnaire="template.questionnaire"
            />
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn
              color="blue darken-1"
              text
              @click="$emit('updateQuestionnaire', template.questionnaire)"
            >
              Update
            </v-btn>
          </v-card-actions>
          <v-divider />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import QuestionnaireForm from '@/components/forms/QuestionnaireFieldEdit/QuestionnaireForm.vue'

export default {
  components: {
    QuestionnaireForm
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    workbench: {
      type: Object,
      default: null
    }
  }
}
</script>
