<template>
  <v-container
    v-if="template"
    fluid
    style="height: 100%; overflow-y: hidden;"
  >
    <v-row
      no-gutters
      justify="center"
    >
      <v-icon
        class="x-icon-position"
        color="primary"
        @click="$emit('close-drawer')"
      >
        mdi-arrow-collapse-right
      </v-icon>
      <v-col
        cols="9"
        class="pa-4"
      >
        <div
          class="editor-overview pa-6"
        >
          <editor-content
            :editor="editor"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import StarterKit from '@tiptap/starter-kit'
import HashTag from '@/pages/TemplateEditor/HashTag/HashTag'
import Comment from '@/pages/TemplateEditor/Comment/Comment'
import FontSize from '@/pages/TemplateEditor/FontSize/FontSize'
import CustomTable from '@/pages/TemplateEditor/CustomTable/CustomTable.js'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import { Color } from '@/tiptap/extensions/colorStyle'
import Heading from '@tiptap/extension-heading'
import Typography from '@tiptap/extension-typography'
import Paragraph from '@tiptap/extension-paragraph'
import Underline from '@tiptap/extension-underline'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
import CharacterCount from '@tiptap/extension-character-count'
import { DropCap } from '@/pages/TemplateEditor/DropCap/dropCap'
import { mapState } from 'vuex'

export default {
  components: {
    EditorContent
  },
  props: {
    template: {
      type: Object,
      default: null
    }
  },

  data: (instance) => {
    return {
      editor: null,
      provider: null,
      ydoc: null
    }
  },

  computed: {
    ...mapState({
      token: state => state.auth.token
    })
  },
  mounted () {
    this.makeSetup()
  },

  beforeDestroy () {
    this.editor.destroy()
    this.editor.destroy()
    this.ydoc = null
    this.provider = null
  },

  methods: {
    makeSetup () {
      this.editor = new Editor({
        extensions: [
          Document,
          Text,
          Bold,
          Italic,
          Superscript,
          Subscript,
          CharacterCount.configure({
            mode: 'nodeSize'
          }),
          StarterKit.configure({
            history: false,
            paragraph: false,
            heading: false,
            bold: false,
            document: false,
            italic: false,
            text: false
          }),
          Heading.configure({
            levels: [1, 2, 3, 4, 5, 6]
          }),
          TextAlign.configure({
            types: ['heading', 'paragraph'],
            alignments: ['left', 'center', 'right', 'justify']
          }),
          TextStyle,
          FontFamily,
          FontSize,
          Color,
          DropCap,
          Typography,
          CustomTable,
          TableRow,
          TableCell.extend({
            addAttributes () {
              return {
                ...this.parent?.(),
                backgroundColor: {
                  default: '#FFFFFF',
                  // Customize the HTML parsing (for example, to load the initial content)
                  parseHTML: element => element.getAttribute('table-background-color'),
                  // … and customize the HTML rendering.
                  renderHTML: attributes => {
                    return {
                      'table-background-color': attributes.backgroundColor,
                      style: `background-color: ${attributes.backgroundColor}`
                    }
                  }
                },
                border: {
                  default: '2px solid #ced4da;',
                  // Customize the HTML parsing (for example, to load the initial content)
                  parseHTML: element => element.getAttribute('border'),
                  // … and customize the HTML rendering.
                  renderHTML: attributes => {
                    return {
                      'border': attributes.border,
                      style: `border: ${attributes.border}`
                    }
                  }
                },
                colwidth: {
                  default: null,
                  parseHTML: (element) => {
                    const colwidth = element.getAttribute('colwidth')
                    const value = colwidth ? [parseInt(colwidth, 10)] : null
                    return value
                  },
                  renderHTML: (attributes) => {
                    return {
                      colwidth: attributes.colwidth,
                      style: attributes.colwidth
                        ? `width: ${attributes.colwidth}px`
                        : null
                    }
                  }
                }
              }
            }
          }),
          TableHeader.extend({
            addAttributes () {
              return {
                ...this.parent?.(),
                colwidth: {
                  default: null,
                  parseHTML: (element) => {
                    const colwidth = element.getAttribute('colwidth')
                    const value = colwidth ? [parseInt(colwidth, 10)] : null
                    return value
                  },
                  renderHTML: (attributes) => {
                    return {
                      colwidth: attributes.colwidth,
                      style: attributes.colwidth
                        ? `width: ${attributes.colwidth}px`
                        : null
                    }
                  }
                }
              }
            }
          }),
          Paragraph.extend({
            addAttributes () {
              return {
                color: {
                  default: null,
                  // Take the attribute values
                  renderHTML: attributes => {
                  // … and return an object with HTML attributes.
                    return {
                      style: `color: ${attributes.color}`
                    }
                  }
                },
                lineHeight: {
                  default: '1.2',
                  renderHTML: attributes => {
                  // … and return an object with HTML attributes.
                    return {
                      style: `line-height: ${attributes.lineHeight}`
                    }
                  }
                }
              }
            }
          }),
          Underline.configure({
            HTMLAttributes: {
              class: 'underline'
            }
          }),
          Comment,
          HashTag
        ]
      })
      this.editor.setOptions({ editable: false })
      const content = this.template.templateData.json
      this.editor.commands.setContent(content)
    }
  }
}
</script>

<style scoped>
.x-icon-position {
  position: absolute;
  top: 10px;
  right: 15px;
}
.editor-overview {
  height: 84vh;
  width: 80vh;
  background: #fffffe;
  border: 1px solid #b1afaf;
  border-radius: 0.2rem;
  margin: 1rem 0;
  position: relative;
  overflow-y: auto;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 2px 4px 8px 5px rgba(0, 0, 0, 0.2);
}
</style>
