<template>
  <v-card
    flat
    color="white"
  >
    <v-toolbar
      v-if="hasToolbar"
      flat
      class="sticky-toolbar-company"
    >
      <v-toolbar-title>{{ $t('profile|company_profile') }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        rounded
        icon
        @click="$emit('close')"
        @mouseover="hoverCloseButtonIcon = true"
        @mouseleave="hoverCloseButtonIcon = false"
      >
        <v-icon
          v-if="hoverCloseButtonIcon"
          small
        >
          mdi-close
        </v-icon>
        <v-icon
          v-else
        >
          mdi-close
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-title
      v-if="!hasToolbar"
      class="d-flex justify-space-between"
    >
      {{ $t('clients|company') }}
    </v-card-title>
    <v-card-text class="mt-4">
      <v-text-field
        v-model="form.company_name"
        :label="$t('clients|company_name')"
        :readonly="client.realClient"
        dense
        outlined
      />
      <v-text-field
        v-model="form.company_type"
        :label="$t('clients|company_type')"
        :readonly="client.realClient"
        dense
        outlined
      />
      <v-text-field
        v-model="form.rcs_number"
        :label="$t('clients|registration_number')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.shared_capital"
        :label="$t('clients|share_capital')"
        :readonly="client.realClient"
        type="number"
        outlined
        dense
      >
        <v-menu
          slot="prepend-inner"
          size="18"
          class="ml-n3 mt-1"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <div
                v-if="form.selected_currency"
                class="mr-2 mt-1"
              >
                {{ form.selected_currency.symbol }}
                <v-icon
                  size="18"
                  class="ml-n2"
                >
                  mdi-menu-down
                </v-icon>
              </div>
              <div
                v-else
                class="mr-2 mt-1"
              >
                {{ Currencies.EUR.symbol }}
                <v-icon
                  size="18"
                  class="ml-n2"
                >
                  mdi-menu-down
                </v-icon>
              </div>
            </div>
          </template>
          <v-list
            style="max-height: 400px; overflow-y: auto;"
          >
            <v-list-item
              v-for="(currency, index) in Currencies"
              :key="index"
              @click="form.selected_currency = currency"
            >
              <v-list-item-title>{{ currency.symbol }} - {{ currency.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-text-field>
      <v-text-field
        v-model="form.date_of_registration"
        :label="$t('clients|date_of_registration')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.place_of_registration"
        :label="$t('clients|company_registration_place')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.address"
        :label="$t('clients|address')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.city"
        :label="$t('clients|city')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.postal_code"
        :label="$t('clients|postal_code')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.email"
        :label="$t('common|email')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.company_representative_name"
        :label="$t('clients|company_representative')"
        :readonly="client.realClient"
        outlined
        dense
      />
      <v-text-field
        v-model="form.company_representative_email"
        :label="$t('clients|company_representative') + ' ' + $t('common|email')"
        :readonly="client.realClient"
        outlined
        dense
      />
    </v-card-text>
    <div
      style="position: sticky; bottom: 5px;;"
    >
      <v-toolbar
        flat
        dense
        style="width: 100%"
      >
        <v-btn
          v-if="hasBackButton"
          text
          rounded
          class="mb-1"
          @click="getBack"
        >
          <v-icon
            class="mr-1"
          >
            mdi-undo
          </v-icon>
          {{ $t('actions|back') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!client.realClient"
          color="primary"
          class="mb-2"
          rounded
          dense
          small
          min-width="150"
          @click="submit"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Currencies from '@/enums/Currencies.json'

const formData = {
  company_name: null,
  company_registration: null,
  company_type: null,
  shared_capital: null,
  selected_currency: Currencies.EUR,
  rcs_number: null,
  legal_representative: null,
  email: null,
  address: null
}

export default {
  props: {
    client: {
      type: Object,
      default: () => ({
        files: []
      })
    },
    hasFileUploader: {
      type: Boolean,
      default: true
    },
    hasToolbar: {
      type: Boolean,
      default: true
    },
    hasBackButton: {
      type: Boolean,
      default: true
    },
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    let form
    if (this.client && this.client.clientData) form = this.client.clientData
    else form = { ...formData }
    return {
      loading: false,
      error: null,
      form,
      hoverCloseButtonIcon: false,
      Currencies
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    })
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast'
    }),
    reset () {
      this.form = { ...formData }
    },
    async submit () {
      this.$emit('submit', {
        form: this.form,
        files: this.client.documents,
        actualTab: this.actualTab
      })
    },
    getBack () {
      this.$emit('get-back')
    }
  }
}
</script>

<style lang="scss" scoped>
.general-form {
  position: relative;
  display: flex;
  flex-direction: column;
}
.sticky-toolbar-company {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
