<template>
  <v-row
    no-gutters
  >
    <v-col
      :cols="colsLeft"
    >
      <v-card
        flat
        outlined
        :height="workbenchHeight / 1.95 + 'px'"
        style="overflow-y: auto; overflow-x: hidden;"
      >
        <v-toolbar
          dense
          flat
          height="45"
          color="lightBackground"
          style="position: sticky; top: 0px; z-index: 1;"
        >
          <v-subheader
            class="ml-n7"
          >
            {{ $t('cases|templates') }}
          </v-subheader>
          <v-spacer />
          <v-btn
            v-if="!lavviraCaseForm"
            x-small
            rounded
            color="primary"
            @click="addWorkbenchTemplate"
          >
            <v-icon
              x-small
              class="mr-1"
            >
              mdi-plus
            </v-icon>
            {{ $t('actions|add_new_template') }}
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row
            v-if="account.accountType === 'lawyer' || account.accountType === 'god'"
            align="center"
          >
            <div
              v-for="template in currentWorkbench.templates"
              :key="template._id"
              class="mx-2 my-4"
            >
              <TemplateCardCaseModels
                :template="template"
                :workbench="currentWorkbench"
                :current-case-form="currentCaseForm"
                :from-drawer="true"
                @selectTemplate="selectTemplate(template)"
                @updateTemplateName="updateTemplateName"
                @delete="deleteWorkbenchTemplate(currentWorkbench, template)"
                @close-drawer="$emit('close-drawer');"
              />
            </div>
          </v-row>
          <div
            v-if="!currentWorkbench.templates.length"
            class="center"
            style="font-size: 15px; color: black;"
          >
            {{ $t('expressions|no_actual_workbench_templates') }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-if="account.accountType === 'lawyer' || account.accountType === 'god'"
      :cols="colsRight"
    >
      <v-card
        flat
        outlined
        :height="workbenchHeight / 1.95 + 'px'"
        style="overflow-y: auto; overflow-x: hidden;"
        class="ml-2"
      >
        <v-toolbar
          dense
          flat
          height="45"
          color="lightBackground"
          style="position: sticky; top: 0px; z-index: 1;"
        >
          <v-subheader
            class="ml-n7"
          >
            {{ $t('mixed|required_workbench_documents') }}
          </v-subheader>
          <div class="text-center">
            <v-dialog
              v-model="infoDialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="info"
                  dark
                  v-bind="attrs"
                  class="ml-n3 mt-n1"
                  v-on="on"
                >
                  mdi-information-variant
                </v-icon>
              </template>

              <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                  {{ $t('mixed|workbench_documents') }}
                </v-card-title>

                <v-card-text class="mt-4">
                  {{ $t('expressions|assigning_documents_info') }}
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    @click="infoDialog = false"
                  >
                    {{ $t('actions|close') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <v-spacer />
          <v-btn
            v-if="!lavviraCaseForm"
            x-small
            rounded
            color="primary"
            class="ml-4 mr-4"
            @click="assignDocDialog = true"
          >
            <v-icon
              x-small
              class="mr-1"
            >
              mdi-link
            </v-icon>
            {{ $t('actions|assign_document') }}
          </v-btn>
        </v-toolbar>
        <div
          class="d-flex"
        >
          <WorkbenchDocumentList
            id="document-list-container"
            :documents="currentWorkbench.requiredDocuments"
            @delete-required-doc="deleteRequiredWbDoc"
          />
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-model="assignDocDialog"
      max-width="550"
    >
      <AssignWorkbenchDocuments
        :current-case-form="currentCaseForm"
        :current-workbench="currentWorkbench"
        @close-assign-dialog="assignDocDialog = false"
        @apply-doc-suggestions="applyDocSuggestions"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import caseModelWorkbenchMixin from './CaseModelMixins/caseModelWorkbenchMixin'
import TemplateCardCaseModels from './TemplateCardCaseModels.vue'
import WorkbenchDocumentList from './WorkbenchDocumentList.vue'
import AssignWorkbenchDocuments from '@/pages/CaseFormBuilder/AssignWorkbenchDocuments'

export default {
  components: {
    TemplateCardCaseModels,
    WorkbenchDocumentList,
    AssignWorkbenchDocuments
  },
  mixins: [caseModelWorkbenchMixin],
  props: {
    currentCaseFormDrawer: {
      type: Object,
      default: null
    },
    currentWorkbenchDrawer: {
      type: Object,
      default: null
    },
    workbenchHeight: {
      type: String,
      default: null
    },
    lavviraCaseForm: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
