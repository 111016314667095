<template>
  <v-dialog
    v-model="dialog"
    keydown
    max-width="1000"
  >
    <v-card
      style="overflow-x: hidden;"
      class="px-4"
    >
      <v-toolbar
        flat
        text="center"
      >
        <span class="font-weight-light">{{ $t('documents|export_documents') }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row>
        <v-col>
          <v-card
            class="ml-4 mt-4"
            max-width="300"
          >
            <v-card-text
              class="py-4"
            >
              {{ $t('documents|documents') }}
            </v-card-text>
            <v-divider />
            <v-card
              class="scroll"
              min-height="300"
              max-height="300"
              max-width="300"
            >
              <v-list v-if="clientDocuments.length">
                <v-list-item-group
                  v-model="selected"
                  multiple
                >
                  <template v-for="doc in clientDocuments">
                    <v-list-item
                      :key="doc._id"
                      :value="doc"
                      active-class="blue--text text--accent-4"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon
                              v-if="isIcon(doc.fileType, doc.raw.originalname)"
                              :color="getItemColor(doc.fileType)"
                            >
                              {{ getIconType(doc.fileType, doc.raw.originalname) }}
                            </v-icon>
                            <img
                              v-if="doc.fileType === 'application/vnd.apple.pages' || doc.fileType === 'application/vnd.apple.numbers'"
                              :src="getSvg(doc.fileType, doc.raw.originalname)"
                              height="24px"
                              width="auto"
                            >
                            <img
                              v-if="doc.fileType === 'application/octet-stream' && !isIcon(doc.fileType, doc.raw.originalname)"
                              :src="getOctetValue(doc.raw.originalname)"
                              height="24px"
                              width="auto"
                              class="mr-3"
                            >
                            {{ doc.raw.originalname }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon
                            :input-value="active"
                            color="primary accent-4"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
              <v-list v-if="!clientDocuments.length">
                <v-list-item-group
                  v-model="selected"
                  multiple
                >
                  <template v-for="doc in filteredDocuments">
                    <v-list-item
                      :key="doc._id"
                      :value="doc"
                      active-class="blue--text text--accent-4"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon
                              v-if="isIcon(doc.fileType, doc.raw.originalname)"
                              :color="getItemColor(doc.fileType)"
                            >
                              {{ getIconType(doc.fileType, doc.raw.originalname) }}
                            </v-icon>
                            <img
                              v-if="doc.fileType === 'application/vnd.apple.pages' || doc.fileType === 'application/vnd.apple.numbers'"
                              :src="getSvg(doc.fileType, doc.raw.originalname)"
                              height="24px"
                              width="auto"
                            >
                            <img
                              v-if="doc.fileType === 'application/octet-stream' && !isIcon(doc.fileType, doc.raw.originalname)"
                              :src="getOctetValue(doc.raw.originalname)"
                              height="24px"
                              width="auto"
                              class="mr-3"
                            >
                            {{ doc.raw.originalname }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon
                            :input-value="active"
                            color="primary accent-4"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            class="mt-4"
            max-width="300"
          >
            <v-card-text
              class="py-4"
            >
              {{ $t('documents|export_list') }}
            </v-card-text>
            <v-divider />
            <v-card
              class="scroll"
              min-height="300"
              max-height="300"
              max-width="300"
            >
              <v-list>
                <v-list-item-group>
                  <template v-for="(item, index) in selected">
                    <v-list-item
                      :key="index"
                      active-class="red--text text--accent-4"
                    >
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon
                              v-if="isIcon(item.raw.mimetype, item.raw.originalname)"
                              :color="getItemColor(item.raw.mimetype)"
                            >
                              {{ getIconType(item.raw.mimetype, item.raw.originalname) }}
                            </v-icon>
                            <img
                              v-if="item.raw.mimetype === 'application/vnd.apple.pages' || item.raw.mimetype === 'application/vnd.apple.numbers'"
                              :src="getSvg(item.raw.mimetype, item.raw.originalname)"
                              height="24px"
                              width="auto"
                            >
                            <img
                              v-if="item.raw.mimetype === 'application/octet-stream' && !isIcon(item.raw.mimetype, item.raw.originalname)"
                              :src="getOctetValue(item.raw.originalname)"
                              height="24px"
                              width="auto"
                              class="mr-3"
                            >
                            {{ item.raw.originalname }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon
                            color="primary accent-4"
                            @click="selected.splice(index, 1)"
                          >
                            mdi-close
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-card>
        </v-col>
        <v-col class="mr-6 mt-4">
          <div>
            <span>{{ selected.length }} {{ $t('expressions|zip_export_info') }}</span>
          </div>
          <v-text-field
            v-model="zipNameInput"
            class="mt-4 mr-4"
            :label="`ZIP ${$t('common|name').toLowerCase()}`"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          rounded
          small
          :disabled="disabledRule"
          :loading="loading"
          min-width="150"
          class="mb-4"
          @click="createZip()"
        >
          {{ $t('actions|export') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { mapState, mapActions } from 'vuex'
import { mdiFileImage, mdiFilePdfBox, mdiFileWord, mdiMicrosoftExcel } from '@mdi/js'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      document: null,
      clientDocuments: [],
      selected: [],
      zipNameInput: null,
      zipPassword: '',
      filteredDocuments: []
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      clients: state => state.clients.clients,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments
    }),
    disabledRule () {
      let rule
      if (this.selected.length) rule = false
      else rule = true
      return rule
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    }
  },
  created () {
    EventBus.$on('dialog-export-document', this.onExportDocumentEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-export-document', this.onExportDocumentEvent)
  },
  methods: {
    ...mapActions({
      updateDocument: 'documents/updateDocument',
      downloadDocument: 'documents/downloadDocument'
    }),
    reset () {
      this.document = null
      this.clientDocuments = []
      this.selected = []
      this.zipNameInput = null
      this.zipPassword = ''
      this.filteredDocuments = []
      this.loading = false
    },
    onExportDocumentEvent (documents, info) {
      if (info && info === 'from-client-section' && documents && Array.isArray(documents)) {
        this.clientDocuments = documents
      }
      if (info && info === 'from-documents-section') {
        this.filteredDocuments = documents
      }
      this.dialog = true
    },
    async downloadDocument (path) {
      const { data } = await this.$axios({
        url: path,
        method: 'GET',
        responseType: 'blob' // important
      })
      return data
    },
    async createZip () {
      this.loading = true
      let zipNameInput = this.zipNameInput
      let zip = new JSZip()
      let docs = zip.folder('documents')
      for (let i = 0; i < this.selected.length; i++) {
        // we need to remove .files/ from path. .files is available in backend
        const slicedPath = this.selected[i].raw.path.slice(6, this.selected[i].raw.path.length)
        const path = `/downloadDocument/${slicedPath}`
        const file = await this.downloadDocument(path)
        docs.file(this.selected[i].raw.originalname, new Blob([file]))
      }
      zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, zipNameInput)
        }).catch((err) => {
          console.error('createZip', err)
          this.loading = false
        }).finally(() => {
          this.reset()
          this.dialog = false
        })
    },
    getIconType (fileType, docName) {
      switch (fileType) {
      case 'application/pdf':
        return mdiFilePdfBox
      case 'image/jpeg':
        return mdiFileImage
      case 'image/png':
        return mdiFileImage
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return mdiFileWord
      case 'application/msword':
        return mdiFileWord
      case 'application/rtf':
        return mdiFileWord
      case 'application/vnd.ms-word.document.macroenabled.12':
        return mdiFileWord
      case 'application/msword-template':
        return mdiFileWord
      case 'application/vnd.oasis.opendocument.text':
        return mdiFileWord
      case 'application/vnd.oasis.opendocument.text-template':
        return mdiFileWord
      case 'application/vnd.oasis.opendocument.text-flat-xml':
        return mdiFileWord
      case 'application/vnd.ms-excel':
        return mdiMicrosoftExcel
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return mdiMicrosoftExcel
      case 'application/vnd.oasis.opendocument.spreadsheet':
        return mdiMicrosoftExcel
      case 'application/octet-stream':
        return 'mdi-file'
      default:
        return 'mdi-file'
      }
    },
    getItemColor (fileType) {
      switch (fileType) {
      case 'application/pdf':
        return '#c11e07'
      case 'image/jpeg':
        return '#ffbd2e'
      case 'image/png':
        return 'blue'
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '#2372ba'
      case 'application/msword':
        return '#2372ba'
      case 'application/rtf':
        return '#2372ba'
      case 'application/vnd.ms-word.document.macroenabled.12':
        return '#2372ba'
      case 'application/msword-template':
        return '#2372ba'
      case 'application/vnd.oasis.opendocument.text':
        return '#2372ba'
      case 'application/vnd.oasis.opendocument.text-template':
        return '#2372ba'
      case 'application/vnd.oasis.opendocument.text-flat-xml':
        return '#2372ba'
      case 'application/vnd.ms-excel':
        return 'green darken-3'
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'green darken-3'
      case 'application/vnd.oasis.opendocument.spreadsheet':
        return 'green darken-3'
      case 'application/octet-stream':
        return 'grey lighten-1'
      default:
        return 'grey'
      }
    },
    getSvg (fileType, docName) {
      if (docName && docName.includes('.pages')) return require(`@/assets/pages.svg`)
      if (docName && docName.includes('.numbers')) return require(`@/assets/numbers.png`)
      if (fileType && fileType === 'application/vnd.apple.pages') return require(`@/assets/pages.svg`)
      if (fileType && fileType === 'application/vnd.apple.numbers') return require(`@/assets/numbers.png`)
    },
    getOctetValue (docName) {
      if (docName && docName.includes('.pages')) return require(`@/assets/pages.svg`)
      if (docName && docName.includes('.numbers')) return require(`@/assets/numbers.png`)
    },
    isIcon (fileType, docName) {
      let rule = true
      if (fileType === 'application/vnd.apple.pages' || fileType === 'application/vnd.apple.numbers') {
        rule = false
      } else if (fileType === 'application/octet-stream') {
        if (docName && docName.includes('.pages')) rule = false
        if (docName && docName.includes('.numbers')) rule = false
      }
      return rule
    }
  }
}
</script>

<style scoped>
.scroll {
   overflow-y: auto
}
</style>
