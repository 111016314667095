<template>
  <v-dialog
    v-model="dialog"
    keydown
    max-width="700px"
    transition="dialog-bottom-transition"
    content-class="vdialognew"
    @keydown.esc="close()"
    @click:outside="close()"
  >
    <div v-if="template && Object.keys(template).length">
      <v-container
        fill-height
        fluid
      >
        <v-expand-transition>
          <v-card
            v-show="showCompany"
            v-if="company"
            width="100%"
          >
            <v-toolbar
              flat
              width="100%"
              :rounded="false"
            >
              <v-icon
                color="primary"
              >
                mdi-account-multiple-plus
              </v-icon>
              <v-toolbar-title
                v-if="templateName"
                class="ml-2"
              >
                {{ $t('actions|share') }} {{ templateName }} {{ $t('common|with_company_or_group') }}
              </v-toolbar-title>
              <v-btn
                v-if="!inCase"
                icon
                style="position: absolute; top: 5px; right: 13px;"
                @click="close()"
                @mouseover="hoverCloseButtonIcon = true"
                @mouseleave="hoverCloseButtonIcon = false"
              >
                <v-icon
                  v-if="hoverCloseButtonIcon"
                  small
                >
                  mdi-close
                </v-icon>
                <v-icon
                  v-else
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-select
                v-model="selectedItem"
                :items="items"
                outlined
                :label="$t('actions|select')"
                dense
                required
                :rules="[(v) => !!v || $t('fields|required')]"
                :clearable="true"
                class="mt-2 mx-3"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                small
                rounded
                min-width="150"
                :disabled="!selectedItem"
                class="mb-1"
                @click="shareTemp(selectedItem)"
              >
                {{ $t('actions|share') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expand-transition>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { mapState, mapActions } from 'vuex'
import { mdiClipboardPlusOutline } from '@mdi/js'

export default {
  components: { },
  data () {
    return {
      actualTab: {},
      templateId: '',
      template: {},
      templateName: '',
      selectedDocuments: [],
      dialog: false,
      userRole: '',
      selectedClient: '',
      selectedItem: '',
      hoverCloseButtonIcon: false,
      clipboardIcon: mdiClipboardPlusOutline,
      showCompany: true,
      showPersonal: true,
      inCase: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      clients: state => state.clients.clients
    }),
    items () {
      let itemsArray = []
      if (this.actualTab.isCompanyTab) {
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          const memberShipInGroupIndex = group.groupMembers.findIndex(gm => gm._id === this.account._id)
          if (memberShipInGroupIndex !== -1) {
            itemsArray.push(group.groupName)
          }
        }
      } else {
        itemsArray.push(this.company.companyData.companyName)
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          const memberShipInGroupIndex = group.groupMembers.findIndex(gm => gm._id === this.account._id)
          if (memberShipInGroupIndex !== -1) {
            const actualGroupIndex = this.template.sharedWith.groups.findIndex(g => g._id === group._id)
            if (this.template && actualGroupIndex === -1) itemsArray.push(group.groupName)
          }
        }
      }
      return itemsArray
    },
    clientsList () {
      const clients = this.clients.map((client) => {
        let displayName
        if (client.clientType === 'individual') {
          if (client.clientData.given_names) {
            displayName = `${client.clientData.given_names} ${client.clientData.surname}`
          } else {
            displayName = `${this.$t('common|pending')} ${this.$t('common|name')}`
          }
        } else {
          if (client.clientData.company_name) {
            displayName = client.clientData.company_name
          } else {
            displayName = `${this.$t('common|pending')} ${this.$t('clients|company_name')}`
          }
        }
        return {
          _id: client._id,
          displayName
        }
      })
      return clients
    }
  },
  watch: {
    selectedClient (value) {
      if (value) {
        this.showCompany = false
      } else {
        this.showCompany = true
      }
    },
    selectedItem (value) {
      if (value) {
        this.showPersonal = false
      } else {
        this.showPersonal = true
      }
    }
  },
  created () {
    EventBus.$on('dialog-share-template', this.onShareTemplateEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-share-template', this.onShareTemplateEvent)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateTemplate: 'templates/updateTemplate',
      updateCompanyTemplate: 'companyTemplates/updateCompanyTemplate',
      updateGoupTemplate: 'groupTemplates/updateGroupTemplate'
    }),
    validate () {
      this.$v.$touch()
      if (this.$v.$error) return false
      return true
    },
    onShareTemplateEvent (template, tab) {
      this.templateId = template._id
      this.template = template
      this.templateName = template.name
      this.actualTab = tab || {}
      this.userRole = tab.userRole
      this.dialog = true
    },
    close () {
      this.templateId = ''
      this.templateName = ''
      this.template = {}
      this.selectedClient = ''
      this.selectedItem = ''
      this.selectedDocuments = []
      this.actualTab = {}
      this.userRole = ''
      this.hoverCloseButtonIcon = false
      this.dialog = false
    },
    async shareTemp (selected) {
      let payload
      if (this.company.companyData.companyName === selected) {
        payload = {
          sharedWith: this.company._id,
          type: 'Company'
        }
      }
      if (this.company.companyData.companyName !== selected) {
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          if (group.groupName === selected) {
            payload = {
              sharedWith: group._id,
              type: 'Group'
            }
          }
        }
      }
      try {
        if (this.actualTab.isMyTab && !this.actualTab.isCompanyTab && !this.actualTab.isGroupTab) {
          await this.updateTemplate({
            _id: this.templateId,
            payload
          })
        } else if (this.actualTab.isCompanyTab && !this.actualTab.isMyTab && !this.actualTab.isGroupTab) {
          await this.updateCompanyTemplate({
            _id: this.templateId,
            payload
          })
        } else if (this.actualTab.isGroupTab && !this.actualTab.isCompanyTab && !this.actualTab.isMyTab) {
          await this.updateGoupTemplate({
            _id: this.templateId,
            payload
          })
        } else if (this.actualTab.fromEditor) {
          await this.updateTemplate({
            _id: this.templateId,
            payload
          })
        }
      } catch (error) {
        console.error(error, 'err')
      }
      this.close()
    }
  }
}
</script>

<style>
.vdialognew {
  box-shadow: none !important;
}
</style>
