<template>
  <v-container
    fluid
    fill-height
    class="pa-2 d-flex flex-nowrap"
  >
    <div
      v-if="loading"
      style="width: 100%; height: 100%;"
    >
      <v-overlay
        absolute
        opacity="0.98"
        :value="loading"
      >
        <v-progress-circular
          :size="50"
          color="white"
          indeterminate
        />
        <div style="position: relative; top: 15px; right: 40%;">
          <img
            src="@/assets/Lavirra_logo_SVG.svg"
            height="28px"
            width="auto"
          >
        </div>
      </v-overlay>
    </div>
    <v-card
      class="flex-grow-1 mr-2"
      style="position: relative; height: 100%; width: calc(100% - 310px)"
      :style="workbenchHeight <= 600 ? 'overflow-y: auto;' : ''"
      flat
    >
      <template v-if="currentCaseForm">
        <span class="title">{{ $t('case_models|case_model_overview') }}</span>
        <v-icon
          size="20"
          color="primary"
          class="close-icon-position"
          @click="$emit('close-drawer')"
        >
          mdi-arrow-collapse-right
        </v-icon>
        <v-row
          dense
        >
          <v-col
            cols="12"
            sm="5"
          >
            <v-card
              flat
            >
              <v-card-title>
                <v-hover
                  v-slot="{ hover }"
                >
                  <div
                    class="mt-n2"
                    style="min-width: 100%"
                  >
                    <input
                      v-model="currentCaseForm.name"
                      class="pl-2 ml-n2 rounded input"
                      style="white-space:nowrap; text-overflow: ellipsis; min-width: 100%"
                      :style="hover && !flatFields.includes(currentCaseForm.name) ? 'background-color: #eee; text-overflow: initial;' : flatFields.includes(currentCaseForm.name) ? 'background-color: #CFD8DC' : ''"
                      @focus="flatFields.push(currentCaseForm.name)"
                      @blur="changeCaseModelName(currentCaseForm.name); flatFields.splice(0, 1)"
                    >
                  </div>
                </v-hover>
              </v-card-title>
              <v-card-text>
                <div>
                  <div>
                    <v-hover v-slot:default="{ hover }">
                      <div>
                        <v-menu
                          open-on-hover
                          bottom
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-card-text
                              :class="focus ? 'ml-n4 mt-n6 grey--text text--darken-2' : 'ml-n4 mt-n6 text-truncate grey--text text--darken-2 rounded-pill'"
                              style="max-width: 450px;"
                            >
                              {{ $t('common|description') }}:
                              <span
                                :id="`case-model-description-${currentCaseForm._id}`"
                                :contenteditable="hover || focus"
                                :style="hover || focus ? `width: 180px; outline: none; color: ${$vuetify.theme.themes.light.primary}; background-color:  ${$vuetify.theme.themes.light.background}; border-radius: 10px; padding: 5px;` : `padding: 5px;`"
                                v-bind="attrs"
                                v-on="on"
                                @focus="focus = true"
                                @blur="changeDescription(currentCaseForm._id, currentCaseForm)"
                              >
                                {{ currentCaseForm.description }}
                              </span>
                            </v-card-text>
                          </template>

                          <v-card
                            max-width="550"
                          >
                            <v-card-text>
                              {{ currentCaseForm.description }}
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-hover>
                  </div>
                  <div class="mt-n5">
                    {{ $t('common|category') }}: {{ translateCategoryIfNeeded(currentCaseForm.category) }}
                    <v-menu
                      bottom
                      left
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            color="primary"
                          >
                            mdi-menu-down
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-list
                        v-if="availableCategories(currentCaseForm).length"
                        style="max-height: 400px; overflow-y: auto;"
                      >
                        <v-list-item
                          v-for="(cat, i) in availableCategories(currentCaseForm)"
                          :key="i"
                          three-line
                          @click="changeCaseModelCategory(currentCaseForm, cat.value)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon
                                x-small
                              >
                                mdi-tag-outline
                              </v-icon>
                              {{ cat.value }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ $t('common|created_by') }}:</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ cat.createdBy }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <div
                        v-else
                        class="pa-3"
                        style="background-color: white;"
                      >
                        {{ $t('mixed|no_available_categories') }}
                      </div>
                    </v-menu>
                  </div>
                  <div class="mt-n2">
                    {{ $t('common|date_created') }}: {{ currentCaseForm.createdAt | date }}
                  </div>
                  <div>
                    {{ $t('common|date_updated') }}: {{ currentCaseForm.updatedAt | dateTime }}
                  </div>
                  <div
                    v-if="currentCaseForm.createdBy && currentCaseForm.createdBy.accountName"
                  >
                    {{ $t('common|created_by') }}: {{ currentCaseForm.createdBy.accountName }}
                  </div>
                  <div
                    v-if="currentCaseForm.createdBy && currentCaseForm.createdBy.companyData"
                  >
                    {{ $t('common|created_by') }}: {{ currentCaseForm.createdBy.companyData.companyName }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-card
              flat
              height="208"
              style="overflow-y: auto;"
            >
              <v-toolbar
                dense
                flat
                height="35"
                class="mt-n4 mb-3"
                style="position: sticky; top: 0px; z-index: 1;"
              >
                <v-subheader
                  class="ml-n7 mt-3"
                >
                  {{ $t('cases|participants') }}
                </v-subheader>
              </v-toolbar>
              <v-card-text
                class="px-2 mt-n3"
              >
                <div
                  v-for="(participant, index) in sharedWithMembers"
                  :key="index"
                  class="mx-auto my-1"
                >
                  <v-chip
                    color="primary"
                    outlined
                    x-small
                  >
                    <v-avatar
                      size="18px"
                      class="ml-n3 mr-1"
                    >
                      <img
                        v-if="participant && participant.avatar"
                        alt="Avatar"
                        :src="`${cfg.baseURL}downloadAvatar/${participant._id}/${participant.avatar.raw.filename}`"
                      >
                      <v-avatar
                        v-else
                      >
                        <v-icon
                          color="primary"
                          small
                        >
                          mdi-account
                        </v-icon>
                      </v-avatar>
                    </v-avatar>
                    <span
                      class="text-truncate"
                    >
                      {{ participant.participantName }}
                    </span>
                  </v-chip>
                </div>
              </v-card-text>
              <v-toolbar
                dense
                flat
                height="35"
                style="position: sticky; bottom: 0px; z-index: 1;"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="!account.comapnyId ? on : ''">
                      <v-chip
                        v-if="account.accountType === 'lawyer'"
                        rounded
                        :disabled="disabledRule"
                        color="primary"
                        small
                        class="ml-n2 mt-n2 pr-5"
                        v-bind="attrs"
                        @click.stop.native="shareCaseForm(currentCaseForm)"
                        v-on="on"
                      >
                        <v-icon
                          left
                          x-small
                        >
                          mdi-plus
                        </v-icon>
                        {{ $t('actions|add_new') }}
                      </v-chip>
                    </div>
                  </template>
                  <span>{{ account.companyId ? $t('case_models|share_case_model') : $t('expressions|activate_members_plan_to_share') }}</span>
                </v-tooltip>

                <v-chip
                  v-if="account.accountType === 'lawyer'"
                  rounded
                  color="primary"
                  small
                  class="ml-2 mt-n2"
                  :disabled="currentCaseForm.sharedWith.accounts.length + currentCaseForm.sharedWith.company.length + currentCaseForm.sharedWith.groups.length === 1"
                  @click="removeCaseForm(currentCaseForm)"
                >
                  <v-icon
                    left
                    small
                    style="position: absolute; left 6px;"
                  >
                    mdi-minus
                  </v-icon>
                  <span class="ml-3">{{ $t('actions|delete') }}</span>
                </v-chip>
              </v-toolbar>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-card
              flat
              height="208"
              class="pt-3"
            >
              <v-card-text>
                <v-row
                  justify="end"
                >
                  <div v-if="currentCaseForm.pinned.includes(account._id)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          icon
                          small
                          class="mt-n1"
                          v-on="on"
                          @click.stop="removePinFromDashboard(currentCaseForm)"
                        >
                          <v-icon>
                            mdi-pin
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('actions|remove_from_pinboard') }}</span>
                    </v-tooltip>
                  </div>
                  <div v-else>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          icon
                          small
                          class="mt-n1"
                          v-on="on"
                          @click.stop="pinToDashboard(currentCaseForm)"
                        >
                          <v-icon>
                            mdi-pin-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('actions|add_to_pinboard') }}</span>
                    </v-tooltip>
                  </div>
                </v-row>
                <v-row
                  dense
                  justify="end"
                  class="mt-4"
                >
                  <div
                    v-if="account.accountType === 'lawyer'"
                    class="d-flex flex-column align-center"
                  >
                    <v-btn
                      rounded
                      color="primary"
                      x-small
                      width="100%"
                      class="mb-2"
                      @click="exportCaseForm(currentCaseForm)"
                    >
                      <v-icon
                        x-small
                        left
                        class="case-model-overview-actions-btn-icon"
                      >
                        mdi-download
                      </v-icon>
                      <span class="ml-4">{{ $t('case_models|export_case_model') }}</span>
                    </v-btn>
                    <v-btn
                      rounded
                      color="primary"
                      x-small
                      width="100%"
                      class="mb-2"
                      @click="useCaseForm(currentCaseForm)"
                    >
                      <!-- :disabled="!getAccess(account, company, group, tab)" -->
                      <v-icon
                        x-small
                        left
                        class="case-model-overview-actions-btn-icon"
                      >
                        mdi-briefcase-edit
                      </v-icon>
                      {{ $t('actions|use') }}
                    </v-btn>
                    <v-btn
                      rounded
                      color="primary"
                      x-small
                      width="100%"
                      max-width="450px"
                      class="mb-2"
                      @click="openCaseModelDocumentsOverview"
                    >
                      <v-icon
                        x-small
                        left
                        class="case-model-overview-actions-btn-icon"
                      >
                        mdi-file-outline
                      </v-icon>
                      <span class="ml-4">{{ $t('case_models|case_model_documents_list') }}</span>
                    </v-btn>
                    <!-- <v-btn
                      v-if="account.accountType === 'lawyer'"
                      rounded
                      outlined
                      color="primary"
                      x-small
                      width="100%"
                      max-width="250px"
                      class="mb-1"
                      @click="generalQDialog = true"
                    >
                      All Questionnaires
                      <v-icon
                        x-small
                        right
                      >
                        mdi-help-box
                      </v-icon>
                    </v-btn> -->
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="d-flex align-center pa-2">
          <v-tabs
            v-model="selectedWbTab"
            active-class="white"
            :slider-color="currentCaseForm.workbenches.length === 1 ? 'white' : 'accent'"
            next-icon="mdi-arrow-right"
            prev-icon="mdi-arrow-left"
            show-arrows
            grow
            style="max-width: 90%; overflow: auto;"
            height="35"
          >
            <v-tab
              v-for="workbench in currentCaseForm.workbenches"
              :key="workbench._id"
              :name="workbench.name"
              :style="currentCaseForm.workbenches.length === 1 ? 'color: black; opacity: 1.0; pointer-events: none;' : ''"
              class="tabs-text"
            >
              {{ workbench.name }}
            </v-tab>
          </v-tabs>
          <v-icon
            v-if="account.accountType === 'lawyer'"
            color="primary"
            class="mx-2"
            @click.prevent="addWorkbench(selectedWbTab)"
          >
            mdi-plus
          </v-icon>
          <!-- Edit Workbenches && Add Workbench Menu -->
          <v-menu
            v-if="account.accountType === 'lawyer'"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-dots-horizontal
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in workbenchMenuItems"
                :key="index"
                @click="triggerAction(item.action)"
              >
                <v-icon
                  small
                >
                  {{ item.icon }}
                </v-icon>
                <v-list-item-title class="ml-2">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-row
          class="mt-n3 mb-2"
          width="100%"
        >
          <v-card
            flat
            :height="workbenchHeight >= 600 ? (workbenchHeight - 350) + 'px' : ''"
            width="100%"
            class="mt-2 mx-4"
            style="overflow-y: hidden"
          >
            <v-tabs-items
              v-model="selectedWbTab"
              class="pa-1"
            >
              <v-tab-item
                v-for="workbench in currentCaseForm.workbenches"
                :key="workbench._id"
              >
                <WorkBenchDrawerOverViewCaseModels
                  :current-workbench-drawer="workbench"
                  :current-case-form-drawer="currentCaseForm"
                  :workbench-height="workbenchHeight"
                  :lavvira-case-form="lavviraCaseForm"
                  @close-drawer="$emit('close-drawer')"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-row>
        <v-row>
          <div class="ml-4 mt-n2 d-flex">
            <v-btn
              v-if="!lavviraCaseForm && account.accountType !== 'god'"
              outlined
              rounded
              small
              color="primary"
              link
              :to="`/models/${currentCaseForm._id}/${currentCaseForm.workbenches[0]._id}`"
              class="ml-4"
              @click="$emit('close-drawer')"
            >
              <v-icon
                color="primary"
                small
                class="mr-1"
              >
                mdi-open-in-new
              </v-icon>
              {{ $t('actions|open') }}
            </v-btn>
            <v-btn
              v-if="account.accountType === 'god'"
              outlined
              rounded
              small
              color="primary"
              link
              :to="`/models/${currentCaseForm._id}/${currentCaseForm.workbenches[0]._id}`"
              class="ml-4"
              @click="$emit('close-drawer')"
            >
              <v-icon
                color="primary"
                small
                class="mr-1"
              >
                mdi-open-in-new
              </v-icon>
              {{ $t('actions|open') }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="!lavviraCaseForm"
              outlined
              rounded
              small
              color="primary"
              style="position: absolute; right: 20px;"
              @click.stop="getCaseFormSharedWithLength(currentCaseForm) <= 1 ? deleteCaseForm(currentCaseForm) : removeCaseForm(currentCaseForm)"
            >
              <v-icon
                color="primary"
                small
                class="mr-1"
              >
                mdi-delete
              </v-icon>
              {{ $t('actions|delete_case_model') }}
            </v-btn>
          </div>
        </v-row>
        <ChangeCaseModelCategory
          v-if="!lavviraCaseForm"
          :current-case-form="currentCaseForm"
        />
        <EditCaseModelWorkbenches
          ref="EditCaseModelWorkbenches"
          :current-case-form="currentCaseForm"
        />
        <!-- <v-dialog
          v-model="generalQDialog"
          keydown
          max-width="1200px"
          transition="dialog-bottom-transition"
          style="position: relative;"
        >
          <GeneralQuestionnaireForm
            :key="generalQDialog"
            :template-data="allCurrentTemplateData"
            :workbench="currentWorkbench"
            :current-case-form="currentCaseForm"
            :form-id="currentCaseForm._id"
            @close="generalQDialog = false"
          />
        </v-dialog> -->

        <v-dialog
          v-model="availableDocumentSuggestionsDialog"
          max-width="600"
        >
          <v-card
            class="mx-auto pa-2"
            style="overflow-y: hidden"
          >
            <v-toolbar
              flat
              absolute
              width="100%"
              class="mb-2"
            >
              <v-card-title>
                {{ $t('mixed|required_case_documents') }}
              </v-card-title>
              <v-icon
                style="position: absolute; right: 30px;"
                color="black"
                @click="availableDocumentSuggestionsDialog = false"
              >
                mdi-close
              </v-icon>
            </v-toolbar>
            <v-list
              max-height="70vh"
              style=" overflow-y: auto; overflow-x: hidden;"
            >
              <template v-for="(doc, index) in currentCaseForm.documents">
                <v-list-item
                  :key="doc.id"
                  prepend-icon="mdi-file-hidden"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-file-hidden
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="translateFileInputField(doc)" />
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      @click="deleteRequiredDoc(doc)"
                    >
                      mdi-close
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider
                  v-if="index < currentCaseForm.documents.length - 1 && index !== 0"
                  :key="index"
                />
              </template>
            </v-list>
            <v-btn
              color="primary"
              outlined
              rounded
              small
              width="50%"
              style="position: relative; left: 25%;"
              class="mb-3 mt-6"
              @click="documentSuggestionsDialog = true"
            >
              {{ $t('actions|add_new') }}
              <v-icon small>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="documentSuggestionsDialog"
          max-width="600"
        >
          <DocumentSuggestions
            :current-case-form="currentCaseForm"
            :reset-document-suggestions-dialog="resetDocumentSuggestionsDialog"
            @close-dialog="documentSuggestionsDialog = false"
          />
        </v-dialog>
      </template>
      <Editor
        v-show="false"
        ref="Editor"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { mdiPencil, mdiPlus } from '@mdi/js'
import { saveAs } from 'file-saver'
import { userHasAccess } from '@/utils/utils'
import cfg from '@/config'
import exportTemplate from '../../pages/TemplateEditor/exportDocx.js'
import generalMixin from '@/utils/generalMixin'
import caseModelMixin from '../../pages/CaseFormBuilder/CaseModelMixins/caseModelMixin'
import JSZip from 'jszip'
import ChangeCaseModelCategory from '../../components/dialogs/ChangeCaseModelCategory.vue'
import EditCaseModelWorkbenches from '@/pages/CaseFormBuilder/EditCaseModelWorkbenches.vue'
import WorkBenchDrawerOverViewCaseModels from '../../pages/CaseFormBuilder/WorkBenchDrawerOverViewCaseModels.vue'
import DocumentSuggestions from '@/pages/CaseFormBuilder/DocumentSuggestions'
import Editor from '@/pages/TemplateEditor/Editor.vue'

// import GeneralQuestionnaireForm from '../../components/forms/GeneralQuestionnaireForm.vue'
// import GeneralQuestionairreCardModels from '@/pages/CaseFormBuilder/GeneralQuestionairreCardModels'

export default {
  components: {
    // GeneralQuestionnaireForm,
    // GeneralQuestionairreCardModels,
    ChangeCaseModelCategory,
    EditCaseModelWorkbenches,
    WorkBenchDrawerOverViewCaseModels,
    DocumentSuggestions,
    Editor
  },
  mixins: [generalMixin, caseModelMixin],
  props: {
    currentCaseForm: {
      type: Object,
      default: null
    },
    workbenchHeight: {
      type: String,
      default: null
    },
    lavviraCaseForm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cfg,
      selectedCategory: '',
      tab: null,
      selectedWorkbench: '',
      generalQDialog: false,
      availableDocumentSuggestionsDialog: false,
      documentSuggestionsDialog: false,
      resetDocumentSuggestionsDialog: false,
      selectedWbTab: 0,
      flatFields: [],
      focus: false,
      loading: false,
      workbenchMenuItems: [
        {
          text: this.$t('actions|edit_workbenches'),
          icon: mdiPencil,
          action: 'editWorkbenchesDialog'
        },
        {
          text: this.$t('actions|add_workbench'),
          icon: mdiPlus,
          action: 'addWorkbench'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      caseForms: state => state.caseForms.caseForms
    }),
    currentWorkbench () {
      return this.currentCaseForm.workbenches.find(w => w._id === this.selectedWorkbench)
    },
    allCurrentTemplateData () {
      return this.currentCaseForm.workbenches.reduce((acc, w) => {
        acc.push(
          ...w.templates
        )
        return acc
      }, [])
    },
    sharedWithMembers () {
      const arr = []
      if (this.currentCaseForm.sharedWith.accounts.length) {
        this.currentCaseForm.sharedWith.accounts.forEach((acc) => {
          const participant = {
            _id: acc._id,
            participantName: acc.accountData.firstName && acc.accountData.lastName ? acc.accountData.firstName + ' ' + acc.accountData.lastName : acc.accountName,
            avatar: acc.avatar
          }
          arr.push(participant)
        })
      }
      if (this.currentCaseForm.sharedWith.company.length) {
        this.currentCaseForm.sharedWith.company.forEach((c) => {
          const participant = {
            participantName: c.companyData.companyName,
            avatar: null
          }
          arr.push(participant)
        })
      }
      if (this.currentCaseForm.sharedWith.groups.length) {
        this.currentCaseForm.sharedWith.groups.forEach((g) => {
          const participant = {
            participantName: g.groupName,
            avatar: null
          }
          arr.push(participant)
        })
      }
      return arr
    },
    disabledRule () {
      let rule
      if (this.account.companyId) rule = false
      else rule = true
      return rule
    }
  },
  watch: {
    documentSuggestionsDialog (value) {
      if (value === false) {
        this.resetDocumentSuggestionsDialog = true
      } else {
        this.resetDocumentSuggestionsDialog = false
      }
    }
  },
  async created () {
    EventBus.$on('suggestion-is-selected', this.selectSuggestion)
    EventBus.$on('suggestion-to-delete', this.deleteReqiredDocumentSuggestion)
  },
  beforeDestroy () {
    EventBus.$off('suggestion-to-delete', this.deleteReqiredDocumentSuggestion)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateCaseForm: 'caseForms/updateCaseForm',
      addCaseFormWorkbench: 'caseForms/addCaseFormWorkbench',
      addCaseFormWorkbenchTemplate: 'caseForms/addCaseFormWorkbenchTemplate',
      addCaseFormWorkbenchRequiredDocument: 'caseForms/addCaseFormWorkbenchRequiredDocument',
      deleteCaseModelRequiredDocument: 'caseForms/deleteCaseModelRequiredDocument',
      deleteCaseFormWorkbenchTemplate: 'caseForms/deleteCaseFormWorkbenchTemplate',
      deleteCaseFormAction: 'caseForms/deleteCaseForm'
    }),
    async changeCaseModelName () {
      this.loading = true
      const payload = {
        name: this.currentCaseForm.name
      }
      try {
        await this.updateCaseForm({ _id: this.currentCaseForm._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    async addWorkbench () {
      const res = await this.$dialog.prompt({
        title: this.$t('mixed|workbench_name'),
        text: this.$t('actions|enter_workbench_name'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [{
          text: this.$t('actions|submit'), color: 'primary'
        }]
      })
      if (!res) return
      try {
        const payload = {
          name: res
        }
        await this.addCaseFormWorkbench({ _id: this.currentCaseForm._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    async addWorkbenchTemplate () {
      EventBus.$emit('dialog-add-template-to-workbench', {
        callback: async (templateRef) => {
          const res = await this.$dialog.prompt({
            title: this.$t('templates|template_name'),
            text: this.$t('actions|enter_template_name'),
            textField: {
              // Any addtional props/attrs that will be binded to v-text-field component
              type: 'text'
            }
          })
          if (!res) return
          try {
            const payload = {
              templateRef,
              name: res
            }
            await this.addCaseFormWorkbenchTemplate({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
          } catch (e) {
            console.error(e, 'error')
          }
        }
      })
    },
    selectTemplate (visiblity = true) {
      this.templateIsSelected = visiblity
      if (this.templateIsSelected === false && this.$refs.workbench) {
        const workbench = this.$refs.workbench.find(w => w.workbench._id === this.selectedWorkbench)
        workbench.resetTemplate()
      }
    },
    onQuestionnaire (template) {
      EventBus.$emit('dialog-view-questionairre', {
        _id: this.currentCaseForm._id,
        workbenchId: this.currentWorkbench._id,
        templateId: template._id,
        questionnaire: template.questionnaire
      })
    },
    async selectSuggestion (suggestion) {
      if (!suggestion) return
      try {
        const payload = {
          suggestion
        }
        await this.addCaseFormWorkbenchRequiredDocument({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    async deleteReqiredDocumentSuggestion (s) {
      this.loading = true
      const payload = {
        requiredDocumentsToDelete: s
      }
      try {
        await this.deleteCaseModelRequiredDocument({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    async deleteWorkbenchTemplate (workbench, template) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource'),
        type: 'warning'
      })
      if (!res) return
      await this.deleteCaseFormWorkbenchTemplate({
        _id: this.currentCaseForm._id,
        workbenchId: workbench._id,
        templateId: template._id
      })
    },
    changeCategory (item) {
      EventBus.$emit('open-change-case-model-category-dialog', { item })
    },
    openEditDialog (event) {
      this.$refs.EditCaseModelWorkbenches.dialog = true
    },
    openGeneralQuestionnaire (event) {
      EventBus.$emit('dialog-general-questionnaire', {})
      this.generalQDialog = true
    },
    openCaseModelDocumentsOverview () {
      this.availableDocumentSuggestionsDialog = true
    },
    useCaseForm (caseForm) {
      EventBus.$emit('dialog-add-case-from-case-model', caseForm)
    },
    async shareCaseForm (caseForm) {
      EventBus.$emit('dialog-share-case-model', caseForm)
    },
    async exportCaseForm () {
      this.loading = true
      await this.createZip()
      this.loading = false
    },
    async downloadDocument (path) {
      const { data } = await this.$axios({
        url: path,
        method: 'GET',
        responseType: 'blob' // important
      })
      return data
    },
    async createZip () {
      let zipNameInput = this.currentCaseForm.name
      let zip = new JSZip()

      for (let index = 0; index < this.currentCaseForm.workbenches.length; index++) {
        const wb = this.currentCaseForm.workbenches[index]
        let workbenchFolder = zip.folder(`${wb.name}`)
        for (let i = 0; i < wb.templates.length; i++) {
          const template = wb.templates[i]
          const json = template.templateData.json
          this.$refs.Editor.editor.commands.setContent(json)
          const doc = this.$refs.Editor.editor.view.state.doc
          const type = 'docx'
          const templateName = template.name + '.docx'
          const caseExport = true
          const exported = await exportTemplate(doc, templateName, type, caseExport)
          workbenchFolder.file(template.name, exported)
        }
      }
      this.$refs.Editor.editor.destroy()
      zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, zipNameInput)
        }).catch((err) => {
          console.error('createZip', err)
        })
    },
    triggerAction (actionType) {
      if (actionType === 'addWorkbench') {
        this.addWorkbench()
      }
      if (actionType === 'editWorkbenchesDialog') {
        this.openEditDialog()
      }
    },
    getAccess (account) {
      return userHasAccess(account)
    }
  }
}
</script>

<style lang="scss" scoped>
.workbenches-panel {
  &.is-expanded {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 15;
  }
}
.title {
  text-align: left;
  font: normal normal normal 22px/27px Roboto;
  letter-spacing: 0px;
  color: #4B5056;
  opacity: 1;
  margin-left: 15px;
  margin-top: 10px;
}
.close-icon-position {
  position: absolute;
  top: 5px;
  right: 8px;
}
.pin-position {
  position: absolute;
  top: 50px;
  right: 5px;
}
.status-menu-position {
  position: absolute;
  top: 50px;
  right: 35px;
}
.icon-position {
  position: relative;
  left: 12rem;
  bottom: 1.5rem;
}
.case-model-overview-actions-btn-icon {
  position: absolute;
  left: 3px
}
.questionairre-position {
  position: absolute;
  top: 80px;
  right:10px;
}
.tabs-text {
  text-transform:none !important;
}
</style>
