<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="350"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('template|custom_suggestion_info') }}
        </v-card-title>
        <v-card-text>{{ $t('template|save_field_after_change') }} <br>{{ $t('template|remember_suggestion') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { EventBus } from '@/utils/EventBus'

export default {
  data () {
    return {
      dialog: false
    }
  },
  created () {
    EventBus.$on('open-info-suggestion-dialog', this.openDialog)
  },
  beforeDestroy () {
    EventBus.$off('open-info-suggestion-dialog', this.openDialog)
  },
  methods: {
    openDialog () {
      this.dialog = true
    }
  }
}
</script>
