<template>
  <v-dialog
    v-if="shareCaseModelDialog"
    v-model="shareCaseModelDialog"
    max-width="700px"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        flat
        width="100%"
        :rounded="false"
      >
        <v-icon
          color="primary"
        >
          mdi-account-multiple-plus
        </v-icon>
        <v-toolbar-title
          class="ml-2"
        >
          {{ $t('actions|share') }}: {{ caseForm.name }}
        </v-toolbar-title>
      </v-toolbar>
      <v-btn
        icon
        style="position: absolute; top: 10px; right: 10px;"
        @click="reset(); shareCaseModelDialog = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text>
        <v-select
          v-model="selectedItem"
          :items="items"
          outlined
          :label="$t('actions|select')"
          dense
          required
          :rules="[(v) => !!v || $t('fields|required')]"
          :clearable="true"
          class="mt-2 mx-2"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="mt-n6 mb-2"
          color="primary"
          small
          rounded
          min-width="150"
          :disabled="!selectedItem"
          @click="shareCaseModel(selectedItem)"
        >
          {{ $t('actions|share') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      shareCaseModelDialog: false,
      caseForm: {},
      actualTabCaseForms: {},
      showCompany: true,
      selectedItem: ''
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    items () {
      let itemsArray = []
      if (this.account && this.company) {
        if (this.actualTabCaseForms && this.actualTabCaseForms.isCompanyTab) {
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const memberShipInGroupIndex = group.groupMembers.findIndex(gm => gm._id === this.account._id)
            if (memberShipInGroupIndex !== -1) {
              itemsArray.push(group.groupName)
            }
          }
        } else {
          const actualCompanyIndex = this.caseForm.sharedWith.company.findIndex(c => c._id === this.company._id)
          if (actualCompanyIndex === -1) itemsArray.push(this.company.companyData.companyName)
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const memberShipInGroupIndex = group.groupMembers.findIndex(gm => gm._id === this.account._id)
            if (memberShipInGroupIndex !== -1) {
              const actualGroupIndex = this.caseForm.sharedWith.groups.findIndex(g => g._id === group._id)
              if (this.caseForm && actualGroupIndex === -1) itemsArray.push(group.groupName)
            }
          }
        }
      }
      return itemsArray
    }
  },
  created () {
    EventBus.$on('dialog-share-case-model', this.onShareCaseModelEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-share-case-model', this.onShareCaseModelEvent)
  },
  methods: {
    ...mapActions({
      updateCaseForm: 'caseForms/updateCaseForm',
      addToast: 'toasts/addToast'
    }),
    onShareCaseModelEvent (caseForm, actualTabCaseForms) {
      this.caseForm = caseForm
      this.actualTabCaseForms = actualTabCaseForms
      this.shareCaseModelDialog = true
    },
    reset () {
      this.caseForm = {}
      this.actualTabCaseForms = {}
      this.showCompany = true
      this.selectedItem = ''
    },
    async shareCaseModel (selected) {
      let payload
      if (this.company.companyData.companyName === selected) {
        payload = {
          sharedWith: this.company._id,
          type: 'Company'
        }
      }
      if (this.company.companyData.companyName !== selected) {
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          if (group.groupName === selected) {
            payload = {
              sharedWith: group._id,
              type: 'Group'
            }
          }
        }
      }
      try {
        await this.updateCaseForm({
          _id: this.caseForm._id,
          payload
        })
      } catch (error) {
        console.error(error, 'error')
      } finally {
        this.addToast({
          title: this.$t('expressions|case_model_shared_successfuly'),
          color: 'white',
          snackbarColor: 'success'
        })
        this.shareCaseModelDialog = false
        this.reset()
      }
    }
  }
}
</script>
