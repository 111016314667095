<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="700px"
    content-class="dialog"
  >
    <v-card
      v-if="dialog"
      :class="selectedTemplate ? 'dialog' : ''"
    >
      <v-expand-transition>
        <div
          v-if="!selectedTemplate"
        >
          <v-toolbar
            width="100%"
            flat
            style="position: sticky; top: 0; left: 0; z-index: 1000; background-color: white;"
          >
            <v-toolbar-title>{{ $t('actions|select_template') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                @click="dialog = false"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-toolbar
            width="100%"
            flat
            style="position: sticky; top: 64px; left: 0; z-index: 1000; background-color: white;"
          >
            <v-toolbar-items>
              <div
                v-for="(section, index) in availableSections"
                :key="index"
              >
                <v-checkbox
                  v-model="checkboxSelection"
                  multiple
                  :value="section"
                  class="mx-2"
                >
                  <template v-slot:label>
                    <div>
                      {{ section }}
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="py-6">
            <v-list
              v-if="templates.length"
              v-show="checkboxSelection.includes($t('templates|my_templates'))"
              flat
            >
              <v-subheader>{{ $t('templates|my_templates').toUpperCase() }}</v-subheader>
              <v-list-item-group
                v-if="templates.length"
                v-model="selectedTemplate"
                color="primary"
              >
                <v-list-item
                  v-for="(template, i) in templates"
                  :key="i"
                  :value="template"
                >
                  <v-list-item-icon>
                    <v-icon
                      color="primary"
                    >
                      {{ tempIcon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="template.name"
                    />
                    <v-list-item-subtitle>
                      {{ $t('common|description') }}: {{ template.description ? template.description : 'none' }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ $t('common|category') }}: {{ template.category }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="account.companyId"
                  >
                    <v-menu
                      open-on-hover
                      offset-y
                      min-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-account-group
                        </v-icon>
                      </template>
                      <v-list dense>
                        <span class="ml-2">
                          {{ $t('common|created_by') }}:
                        </span>
                        <v-list-item v-if="template.onCreatedBy === 'Account'">
                          <v-list-item-icon>
                            <v-icon
                              color="primary"
                            >
                              mdi-account
                            </v-icon>
                          </v-list-item-icon>
                          {{ template.createdBy.accountData.accountName ? template.createdBy.accountData.accountName : account.accountName }}
                        </v-list-item>
                        <v-list-item v-if="template.onCreatedBy === 'Company'">
                          <v-list-item-icon>
                            <v-icon
                              color="primary"
                            >
                              mdi-domain
                            </v-icon>
                          </v-list-item-icon>
                          {{ template.createdBy.companyData.companyName }}
                        </v-list-item>
                        <span class="ml-2">
                          {{ $t('common|shared_with') }}:
                        </span>
                        <v-list-item
                          v-for="acc in template.sharedWith.accounts"
                          :key="acc._id"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-account
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ acc.accountData.accountName ? acc.accountData.accountName : account.accountName }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-for="c in template.sharedWith.company"
                          :key="c._id"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-domain
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ c.companyData.companyName }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-for="(g, index) in template.sharedWith.groups"
                          :key="index"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-account-group
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ g.groupName }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <span v-else>{{ $t('expressions|no_available_templates') }}</span>
            <v-divider
              v-if="companyTemplates && companyTemplates.length"
              v-show="checkboxSelection.includes(company.companyData.companyName) && checkboxSelection.includes($t('templates|my_templates'))"
            />
            <v-list
              v-if="companyTemplates && companyTemplates.length"
              v-show="checkboxSelection.includes(company.companyData.companyName)"
              flat
            >
              <v-subheader>{{ company.companyData.companyName.toUpperCase() + ' ' + $t('templates|templates').toUpperCase() }}</v-subheader>
              <v-list-item-group
                v-if="companyTemplates && companyTemplates.length"
                v-model="selectedTemplate"
                color="primary"
              >
                <v-list-item
                  v-for="(template, i) in companyTemplates"
                  :key="i"
                  :value="template"
                >
                  <v-list-item-icon>
                    <v-icon
                      color="primary"
                    >
                      {{ tempIcon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="template.name"
                    />
                    <v-list-item-subtitle>
                      {{ $t('common|description') }}: {{ template.description ? template.description : 'none' }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ $t('common|category') }}: {{ template.category }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      open-on-hover
                      offset-y
                      min-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-account-group
                        </v-icon>
                      </template>
                      <v-list dense>
                        <span class="ml-2">
                          {{ $t('common|created_by') }}:
                        </span>
                        <v-list-item v-if="template.onCreatedBy === 'Account'">
                          <v-list-item-icon>
                            <v-icon
                              color="primary"
                            >
                              mdi-account
                            </v-icon>
                          </v-list-item-icon>
                          {{ template.createdBy.accountData.accountName ? template.createdBy.accountData.accountName : account.accountName }}
                        </v-list-item>
                        <v-list-item v-if="template.onCreatedBy === 'Company'">
                          <v-list-item-icon>
                            <v-icon
                              color="primary"
                            >
                              mdi-domain
                            </v-icon>
                          </v-list-item-icon>
                          {{ template.createdBy.companyData.companyName }}
                        </v-list-item>
                        <span class="ml-2">
                          {{ $t('common|shared_with') }}::
                        </span>
                        <v-list-item
                          v-for="acc in template.sharedWith.accounts"
                          :key="acc._id"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-account
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ acc.accountData.accountName ? acc.accountData.accountName : account.accountName }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-for="c in template.sharedWith.company"
                          :key="c._id"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-domain
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ c.companyData.companyName }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-for="(g, index) in template.sharedWith.groups"
                          :key="index"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-account-group
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ g.groupName }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
              <span v-else>{{ $t('expressions|no_available_templates') }}</span>
            </v-list>
            <v-divider
              v-if="filteredGroupTemplates && filteredGroupTemplates.length"
              v-show="checkboxSelection.includes($t('templates|available_group_templates')) && checkboxSelection.length > 1 "
            />
            <v-list
              v-if="filteredGroupTemplates && filteredGroupTemplates.length"
              v-show="checkboxSelection.includes(this.$t('templates|available_group_templates'))"
              v-model="selectedTemplate"
              three-line
              subheader
              flat
            >
              <v-list-item-group
                v-if="filteredGroupTemplates && filteredGroupTemplates.length"
                v-model="selectedTemplate"
                color="primary"
              >
                <v-subheader>{{ $t('templates|available_group_templates').toUpperCase() }}</v-subheader>
                <v-list-item
                  v-for="(template, i) in filteredGroupTemplates"
                  :key="i"
                  :value="template"
                  three-line
                >
                  <v-list-item-icon>
                    <v-icon
                      color="primary"
                    >
                      {{ tempIcon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="template.name"
                    />
                    <v-list-item-subtitle>
                      description: {{ template.description ? template.description : 'none' }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      category: {{ template.category }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      open-on-hover
                      offset-y
                      min-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-account-group
                        </v-icon>
                      </template>
                      <v-list dense>
                        <span class="ml-2">
                          Created by:
                        </span>
                        <v-list-item v-if="template.onCreatedBy === 'Account'">
                          <v-list-item-icon>
                            <v-icon
                              color="primary"
                            >
                              mdi-account
                            </v-icon>
                          </v-list-item-icon>
                          {{ template.createdBy.accountData.accountName ? template.createdBy.accountData.accountName : account.accountName }}
                        </v-list-item>
                        <v-list-item v-if="template.onCreatedBy === 'Company'">
                          <v-list-item-icon>
                            <v-icon
                              color="primary"
                            >
                              mdi-domain
                            </v-icon>
                          </v-list-item-icon>
                          {{ template.createdBy.companyData.companyName }}
                        </v-list-item>
                        <span class="ml-2">
                          {{ $t('common|shared_with') }}::
                        </span>
                        <v-list-item
                          v-for="acc in template.sharedWith.accounts"
                          :key="acc._id"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-account
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ acc.accountData.accountName ? acc.accountData.accountName : account.accountName }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-for="c in template.sharedWith.company"
                          :key="c._id"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-domain
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ c.companyData.companyName }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-for="(g, index) in template.sharedWith.groups"
                          :key="index"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-account-group
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ g.groupName }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
              <span v-else>{{ $t('expressions|no_available_templates') }}</span>
            </v-list>
          </v-card-text>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div
          v-if="selectedTemplate"
        >
          <v-toolbar
            flat
          >
            <v-spacer />
            <v-btn
              icon
              class="mt-n2"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row
            justify="center"
            align="center"
            class="pb-10 pt-8"
          >
            <TemplateCard
              :template="selectedTemplate"
              :deactivated="true"
            />
          </v-row>
        </div>
      </v-expand-transition>
      <v-card-actions>
        <v-btn
          v-if="selectedTemplate"
          text
          rounded
          class="mb-2 ml-2"
          @click="selectedTemplate = null"
        >
          <v-icon
            class="mr-1"
          >
            mdi-undo
          </v-icon>
          {{ $t('actions|back') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="selectedTemplate"
          color="primary"
          rounded
          small
          min-width="150"
          :disabled="!selectedTemplate"
          @click="select"
        >
          {{ $t('actions|use') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { uniqBy } from '@/utils/utils'
import { mdiTextBoxOutline } from '@mdi/js'
import TemplateCard from '../../pages/TemplateEditor/TemplateCard.vue'

export default {
  components: { TemplateCard },
  data () {
    return {
      dialog: false,
      selectedTemplate: null,
      callback: null,
      tempIcon: mdiTextBoxOutline,
      checkboxSelection: []
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      templates: state => state.templates.templates,
      companyTemplates: state => state.companyTemplates.companyTemplates,
      groupTemplates: state => state.groupTemplates.groupTemplates
    }),
    filteredGroupTemplates () {
      let groupTemps = []
      if (!this.account.companyId) return
      this.company.groups.forEach(group => {
        const index = group.groupMembers.findIndex(m => m._id === this.account._id)
        if (index !== -1) {
          this.groupTemplates.forEach(temp => {
            temp.sharedWith.groups.find(g => g._id === group._id)
            if (temp) {
              groupTemps.push(temp)
            }
          })
        }
      })
      const uniqueGroupTemps = uniqBy(groupTemps, '_id')
      return uniqueGroupTemps
    },
    availableSections () {
      let sections = []
      if (this.templates && this.templates.length) {
        sections.push(this.$t('templates|my_templates'))
      }
      if (this.companyTemplates && this.companyTemplates.length) {
        sections.push(this.company.companyData.companyName)
      }
      if (this.filteredGroupTemplates && this.filteredGroupTemplates.length) {
        sections.push(this.$t('templates|available_group_templates'))
      }
      return sections
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    }
  },
  created () {
    EventBus.$on('dialog-add-template-to-workbench', this.onOpen)
  },
  beforeDestroy () {
    EventBus.$off('dialog-add-template-to-workbench', this.onOpen)
  },
  methods: {
    onOpen (event) {
      if (this.templates && this.templates.length) {
        this.checkboxSelection.push(this.$t('templates|my_templates'))
      }
      if (this.companyTemplates && this.companyTemplates.length) {
        this.checkboxSelection.push(this.company.companyData.companyName)
      }
      if (this.filteredGroupTemplates && this.filteredGroupTemplates.length) {
        this.checkboxSelection.push(this.$t('templates|available_group_templates'))
      }
      this.dialog = true
      this.callback = event.callback
    },
    reset () {
      this.dialog = false
      this.selectedTemplate = null
      this.checkboxSelection = []
      this.callback = null
    },
    select () {
      this.callback(this.selectedTemplate)
      this.reset()
    }
  }
}
</script>

<style scoped>
  .dialog {
    overflow-x: hidden;
  }
</style>
