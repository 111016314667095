<template>
  <v-dialog
    v-model="dialog"
    keydown
    persistent
    max-width="600px"
  >
    <v-card
      v-if="document"
    >
      <v-card-title>
        {{ $t('actions|assign_document') }}
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-4">
        <div
          v-if="document.clients.length"
          class="my-3"
        >
          <span>
            {{ $t('documents|document_is_already_assigned') }}:
            <v-chip
              v-for="(client, index) in document.clients"
              :key="index"
            >
              {{ client.clientType === 'individual' ? client.clientData.given_names + ' ' + client.clientData.surname : client.clientData.company_name }}
            </v-chip>
          </span>
        </div>
        <div
          v-if="document.cases.length"
          class="my-3"
        >
          <span>
            {{ $t('documents|document_is_already_assigned') }}:
            <v-chip
              v-for="(_case, index) in document.cases"
              :key="index"
            >
              {{ _case.caseName }}
            </v-chip>
          </span>
        </div>
        <div
          v-if="document.cases.length && document.clients.length"
          class="black--text"
        >
          {{ $t('expressions|document_unassign_if_needed') }}
        </div>
        <div v-if="!document.cases.length || !document.clients.length">
          <v-autocomplete
            v-if="account.accountType === 'lawyer'"
            v-model="selectedClient"
            :items="clientsList"
            :label="$t('actions|select_client')"
            item-text="displayName"
            item-value="_id"
            :rules="[(v) => !!v || $t('fields|required')]"
            dense
            clearable
            outlined
          />
          <v-select
            v-if="selectedClient"
            v-model="selectedCases"
            :items="caseList(selectedClient)"
            item-disabled="disable"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="$t('actions|choose_case')"
            :hint="$t('common|optional')"
            persistent-hint
            outlined
            required
            dense
            item-text="caseName"
            item-value="_id"
            multiple
            clearable
          />
        </div>
        <div v-if="!document.cases.length && account.accountType !== 'lawyer'">
          <v-select
            v-model="selectedCaseByClient"
            :items="cases"
            item-disabled="disable"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="$t('actions|choose_case')"
            :hint="$t('common|optional')"
            persistent-hint
            outlined
            required
            dense
            item-text="caseName"
            return-object
            clearable
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="account.accountType === 'lawyer'"
          color="primary"
          small
          rounded
          min-width="150"
          :disabled="!selectedClient"
          :loading="loading"
          class="mb-3"
          @click="assignDocument('lawyer')"
        >
          {{ $t('actions|submit') }}
        </v-btn>
        <v-btn
          v-else
          color="primary"
          small
          rounded
          min-width="150"
          :disabled="!selectedCaseByClient"
          :loading="loading"
          class="mb-3"
          @click="assignDocument('client')"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'

export default {
  data () {
    return {
      dialog: false,
      selectedClient: null,
      selectedCases: [],
      selectedCaseByClient: null,
      loading: false,
      error: null,
      document: null,
      actualTab: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      clients: state => state.clients.clients,
      companyClients: state => state.companyClients.companyClients,
      groupClients: state => state.groupClients.groupClients,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases
    }),
    clientsList () {
      let displayName
      let clientsArr = []
      if (this.document && this.document.clients.length) {
        this.setDefault(this.document.clients[0])
        clientsArr.push(this.document.clients[0])
      }
      if (this.document && this.document.cases.length) {
        this.clients.forEach(cli => {
          if (cli._id === this.document.cases[0].client) {
            clientsArr.push(cli)
          }
        })
      }
      if (this.document && !this.document.clients.length && !this.document.cases.length) {
        if (this.actualTab.isMyTab) clientsArr = this.clients
        if (this.actualTab.isCompanyTab) clientsArr = this.companyClients
        if (this.actualTab.isGroupTab) {
          this.groupClients.forEach(cl => {
            cl.sharedWith.groups.forEach(id => {
              if (id === this.actualTab.group._id) clientsArr.push(cl)
            })
          })
        }
      }
      const clients = clientsArr.map((client) => {
        if (client.clientType === 'individual') {
          if (client.clientData.given_names) {
            displayName = `${client.clientData.given_names} ${client.clientData.surname}`
          } else {
            displayName = 'Pending Name'
          }
        } else {
          if (client.clientData.company_name) {
            displayName = client.clientData.company_name
          } else {
            displayName = 'Pending Company Name'
          }
        }
        return {
          _id: client._id,
          displayName
        }
      })
      return clients
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    }
  },
  created () {
    EventBus.$on('dialog-assign-document', this.onAddAssignEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-assign-document', this.onAddAssignEvent)
  },
  methods: {
    ...mapActions({
      updateDocument: 'documents/updateDocument'
    }),
    onAddAssignEvent (document, tab) {
      this.document = document
      this.actualTab = tab
      this.dialog = true
    },
    setDefault (client) {
      this.selectedClient = client._id
    },
    reset () {
      this.selectedClient = null
      this.selectedCases = []
      this.actualTab = null
      this.document = null
      this.loading = false
      this.selectedCaseByClient = null
      this.error = null
    },
    caseList (clientId) {
      let availableCases = []
      if (this.cases && this.cases.length) {
        this.cases.forEach(_case => {
          if (_case.client && _case.client._id === clientId) {
            const isAssigned = this.document.cases.find(_c => _c._id === _case._id)
            if (isAssigned) {
              _case.disable = true
            }
            availableCases.push(_case)
          }
        })
      }
      if (this.companyCases && this.companyCases.length) {
        this.companyCases.forEach(_case => {
          if (_case.client._id === clientId) {
            availableCases.push(_case)
          }
        })
      }
      if (this.groupCases && this.groupCases.length) {
        this.groupCases.forEach(_case => {
          if (_case.client._id === clientId) {
            availableCases.push(_case)
          }
        })
      }
      return availableCases
    },
    async assignDocument (accountType) {
      this.loading = true
      if (accountType === 'client') {
        this.selectedClient = this.selectedCaseByClient.client._id
        this.selectedCases.push(this.selectedCaseByClient)
      }
      try {
        await this.updateDocument({
          _id: this.document._id,
          payload: {
            clients: [this.selectedClient],
            cases: this.selectedCases,
            assignClientAndCasesToDocument: true
          }
        })
      } catch (error) {
        console.error(error, 'error')
      } finally {
        this.dialog = false
        this.loading = false
      }
    }
  }
}
</script>
