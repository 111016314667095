<template>
  <div>
    <v-dialog
      v-model="welcomeDialog"
      persistent
      keydown
      max-width="800px"
      min-height="1000px"
      transition="dialog-bottom-transition"
    >
      <v-container
        fill-height
        fluid
        style="background-color: white;"
        class="pa-0"
      >
        <v-card
          width="100%"
        >
          <v-card-title v-if="slides === 1">
            {{ $t('profile|profile_editor') }}
          </v-card-title>
          <v-card-subtitle v-if="slides === 1">
            {{ $t('profile|please_fill') }}
          </v-card-subtitle>
          <v-card-text
            class="text-center mt-6"
          >
            <v-window
              v-model="slides"
            >
              <v-window-item>
                <img
                  class="mx-auto"
                  src="@/assets/Lavvira_monogramm_logo.svg"
                  height="80px"
                  width="auto"
                >
                <h1>{{ $t('common|congratulations') }}</h1>
                <p>{{ $t('welcome|successfully_created_well_done') }}</p>
                <p>{{ $t('profile|please_fill') }}</p>
              </v-window-item>
              <v-window-item>
                <!-- lawyer account form  -->
                <v-form
                  v-if="account && account.accountType === 'lawyer'"
                  class="mt-n7"
                >
                  <v-row
                    class="pa-5"
                  >
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="firstName"
                        v-model="accountData.firstName"
                        :rules="[() => !!accountData.firstName || $t('common|field_required')]"
                        :label="$t('clients|first_name')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="lastName"
                        v-model="accountData.lastName"
                        :rules="[() => !!accountData.lastName || $t('common|field_required')]"
                        :label="$t('clients|surname')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="address"
                        v-model="accountData.address"
                        :rules="[() => !!accountData.address || $t('common|field_required')]"
                        :label="$t('clients|address')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="city"
                        v-model="accountData.city"
                        :rules="[() => !!accountData.city || $t('common|field_required')]"
                        :label="$t('clients|city')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="postal_code"
                        v-model="accountData.postal_code"
                        :rules="[() => !!accountData.postal_code || $t('common|field_required')]"
                        :label="$t('clients|postal_code')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-autocomplete
                        ref="nationality"
                        v-model="accountData.nationality"
                        :rules="[() => !!accountData.nationality || $t('common|field_required')]"
                        :items="CountryList"
                        item-text="en_short_name"
                        item-value="alpha_2_code"
                        :label="$t('profile|country')"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="phone"
                        v-model="accountData.phone"
                        :rules="[() => !!accountData.phone || $t('common|field_required')]"
                        :label="$t('profile|phone')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <div class="ml-8 mt-n8">
                      {{ $t('fields|all_fields_required') }}
                    </div>
                  </v-row>
                  <v-row>
                    <div class="mx-5 mt-n4 pa-3 text-left">
                      {{ $t('profile|adjustment_note') }}
                    </div>
                  </v-row>
                </v-form>

                <!-- form for company type client account -->
                <v-form
                  v-if="account && account.accountType === 'company'"
                  class="mt-n7"
                >
                  <v-row
                    class="pa-5"
                  >
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="companyName"
                        v-model="accountData.companyName"
                        :rules="[() => !!accountData.companyName || $t('common|field_required')]"
                        :label="$t('clients|company_name')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="companyType"
                        v-model="accountData.companyType"
                        :rules="[() => !!accountData.companyType || $t('common|field_required')]"
                        :label="$t('clients|company_type')"
                        hint="Ltd, SARL, SAS, GMbH, EOOD..."
                        persistent-hint
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="sharedCapital"
                        v-model.number="accountData.sharedCapital"
                        :hint="$t('expressions|only_numbers_are_allowed')"
                        type="numbers"
                        :rules="[rules.charType]"
                        :label="$t('profile|shared_capital')"
                        class="purple-input"
                        outlined
                        dense
                        @input="allowOnlyNumbers"
                      >
                        <v-menu
                          slot="prepend-inner"
                          size="18"
                          class="ml-n3 mt-1"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                            >
                              <div
                                v-if="accountData.selectedCurrency"
                                class="mr-2 mt-1"
                              >
                                {{ accountData.selectedCurrency.symbol }}
                                <div>
                                  <v-icon
                                    size="18"
                                    style="position: absolute; left: 18px; bottom: 2px;"
                                  >
                                    mdi-menu-down
                                  </v-icon>
                                </div>
                              </div>
                              <div
                                v-else
                                class="mr-2 mt-1"
                              >
                                {{ Currencies.EUR.symbol }}
                                <div>
                                  <v-icon
                                    size="18"
                                    style="position: absolute; left: 18px; bottom: 10px;"
                                  >
                                    mdi-menu-down
                                  </v-icon>
                                </div>
                              </div>
                            </div>
                          </template>
                          <v-list
                            style="max-height: 400px; overflow-y: auto;"
                          >
                            <v-list-item
                              v-for="(currency, index) in Currencies"
                              :key="index"
                              @click="accountData.selectedCurrency = currency"
                            >
                              <v-list-item-title>{{ currency.symbol }} - {{ currency.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <div
                          v-if="accountData.selectedCurrency"
                          slot="label"
                          :class="accountData.selectedCurrency.symbol.length >= 2 ? 'mr-8' : ''"
                          style="position: absolute; left: 10px;"
                        >
                          Shared capital
                        </div>
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="rcsNumber"
                        v-model="accountData.rcsNumber"
                        :rules="[() => !!accountData.rcsNumber || $t('common|field_required')]"
                        :label="$t('profile|company_registration_number')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="place_of_registration"
                        v-model="accountData.place_of_registration"
                        :rules="[() => !!accountData.place_of_registration || $t('common|field_required')]"
                        :label="$t('clients|company_registration_place')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="address"
                        v-model="accountData.address"
                        :rules="[() => !!accountData.address || $t('common|field_required')]"
                        :label="$t('clients|address')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="city"
                        v-model="accountData.city"
                        :rules="[() => !!accountData.city || $t('common|field_required')]"
                        :label="$t('clients|city')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="postal_code"
                        v-model="accountData.postal_code"
                        :rules="[() => !!accountData.postal_code || $t('common|field_required')]"
                        :label="$t('clients|postal_code')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-autocomplete
                        ref="nationality"
                        v-model="accountData.nationality"
                        :rules="[() => !!accountData.nationality || $t('common|field_required')]"
                        :items="CountryList"
                        item-text="en_short_name"
                        item-value="alpha_2_code"
                        :label="$t('profile|country')"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <div class="ml-8 mt-n8">
                      {{ $t('fields|all_fields_required') }}
                    </div>
                  </v-row>
                  <v-row>
                    <div class="mx-5 mt-n4 pa-3 text-left">
                      {{ $t('profile|adjustment_note') }}
                    </div>
                  </v-row>
                </v-form>

                <!-- form for individual type client account -->
                <v-form
                  v-if="account && account.accountType === 'individual'"
                  class="mt-n7"
                >
                  <v-row
                    class="pa-5"
                  >
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="firstName"
                        v-model="accountData.firstName"
                        :rules="[() => !!accountData.firstName || $t('common|field_required')]"
                        :label="$t('clients|first_name')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="lastName"
                        v-model="accountData.lastName"
                        :rules="[() => !!accountData.lastName || $t('common|field_required')]"
                        :label="$t('clients|surname')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-menu
                        v-model="dateOfBirthMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            ref="date_of_birth"
                            v-model="accountData.date_of_birth"
                            :rules="[() => !!accountData.date_of_birth || $t('common|field_required')]"
                            class="purple-input"
                            outlined
                            dense
                            :label="$t('clients|date_of_birth')"
                            :hint="$t('common|in_format')"
                            persistent-hint
                            readonly
                            v-bind="attrs"
                            required
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="dateOfBirthMenu = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="place_of_birth"
                        v-model="accountData.place_of_birth"
                        :rules="[() => !!accountData.place_of_birth || $t('common|field_required')]"
                        :label="$t('clients|place_of_birth')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="address"
                        v-model="accountData.address"
                        :rules="[() => !!accountData.address || $t('common|field_required')]"
                        :label="$t('clients|address')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="city"
                        v-model="accountData.city"
                        :rules="[() => !!accountData.city || $t('common|field_required')]"
                        :label="$t('clients|city')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        ref="postal_code"
                        v-model="accountData.postal_code"
                        :rules="[() => !!accountData.postal_code || $t('common|field_required')]"
                        :label="$t('clients|postal_code')"
                        class="purple-input"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-autocomplete
                        ref="nationality"
                        v-model="accountData.nationality"
                        :rules="[() => !!accountData.nationality || $t('common|field_required')]"
                        :items="CountryList"
                        item-text="en_short_name"
                        item-value="alpha_2_code"
                        :label="$t('profile|country')"
                        outlined
                        dense
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <div class="ml-8 mt-n8">
                      {{ $t('fields|all_fields_required') }}
                    </div>
                  </v-row>
                  <v-row>
                    <div class="mx-5 mt-n4 pa-3 text-left">
                      {{ $t('profile|adjustment_note') }}
                    </div>
                  </v-row>
                </v-form>
              </v-window-item>
            </v-window>
          </v-card-text>

          <v-card-actions class="mt-8 pb-4">
            <v-btn
              v-if="slides === 0"
              absolute
              bottom
              right
              rounded
              small
              min-width="150"
              color="primary"
              @click="slides++"
            >
              {{ $t('common|next') }}
              <v-icon
                small
                class="ml-2"
              >
                mdi-arrow-right
              </v-icon>
            </v-btn>
            <v-btn
              v-if="slides === 1"
              absolute
              bottom
              left
              rounded
              small
              min-width="150"
              outlined
              class="text-capitalize ml-5"
              @click="skipProfileEdit"
            >
              <div
                style="position: absolute; bottom: -3px;"
              >
                {{ $t('common|skip') }}
              </div>
              <div
                style="position: absolute; bottom: -10px; font-size: 9px;"
              >
                {{ $t('common|not_recommended') }}
              </div>
            </v-btn>
            <v-btn
              v-if="slides === 1"
              absolute
              bottom
              right
              rounded
              small
              min-width="150"
              color="primary"
              class="mr-5"
              @click="updateAccountData"
            >
              {{ $t('profile|update_profile') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CountryList from '@/enums/CountryList.json'
import Currencies from '@/enums/Currencies.json'

export default {
  data () {
    return {
      welcomeDialog: false,
      slides: null,
      accountData: null,
      CountryList,
      Currencies,
      dateOfBirthMenu: false,
      date: null,
      rules: {
        charType: v => !isNaN(v) || this.$t('expressions|only_numbers_are_allowed')
      }
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    form () {
      return {
        firstName: this.accountData.firstName,
        lastName: this.accountData.lastName,
        address: this.accountData.address,
        city: this.accountData.city,
        postal_code: this.accountData.postal_code,
        nationality: this.accountData.nationality,
        phone: this.accountData.phone
      }
    },
    individualClientForm () {
      return {
        firstName: this.accountData.firstName,
        lastName: this.accountData.lastName,
        date_of_birth: this.accountData.date_of_birth,
        place_of_birth: this.accountData.place_of_birth,
        address: this.accountData.address,
        city: this.accountData.city,
        postal_code: this.accountData.postal_code,
        nationality: this.accountData.nationality
      }
    },
    companyClientForm () {
      return {
        companyName: this.accountData.companyName,
        companyType: this.accountData.companyType,
        sharedCapital: this.accountData.sharedCapital,
        rcsNumber: this.accountData.rcsNumber,
        place_of_registration: this.accountData.place_of_registration,
        address: this.accountData.address,
        city: this.accountData.city,
        postal_code: this.accountData.postal_code,
        nationality: this.accountData.nationality
      }
    }
    // disabledButtonRule () {
    //   let rule
    //   if (this.account.accountType === 'individual') {
    //     if (this.account.email && this.accountData.accountName && this.accountData.phone && this.accountData.firstName &&
    //     this.accountData.lastName && this.accountData.date_of_birth && this.accountData.place_of_birth && this.accountData.nationality &&
    //     this.accountData.address && this.accountData.city && this.accountData.postal_code) {
    //       rule = false
    //     } else {
    //       rule = true
    //     }
    //   }
    //   if (this.account.accountType === 'company') {
    //     if (this.account.email && this.accountData.accountName && this.accountData.phone && this.accountData.companyName &&
    //     this.accountData.companyType && this.accountData.sharedCapital && this.accountData.rcsNumber &&
    //     this.accountData.date_of_registration && this.accountData.address &&
    //     this.accountData.city && this.accountData.nationality && this.accountData.postal_code && this.accountData.companyRepresentativeName &&
    //     this.accountData.companyRepresentativeEmail) {
    //       rule = false
    //     } else {
    //       rule = true
    //     }
    //   }
    //   if (this.account.accountType === 'lawyer') {
    //     if (this.accountData.accountName && this.accountData.phone && this.accountData.firstName &&
    //     this.accountData.lastName && this.accountData.nationality &&
    //     this.accountData.address && this.accountData.city && this.accountData.postal_code) {
    //       rule = false
    //     } else {
    //       rule = true
    //     }
    //   }
    //   return rule
    // }
  },
  watch: {
    account: {
      handler (value) {
        if (!value) return
        this.accountData = value.accountData || {}
      },
      immediate: true
    },
    date (val) {
      if (val) {
        this.formatDate(val)
      }
    }
  },
  mounted () {
    if (this.account && this.account.loginCounter > 1) {
      this.slides = 1
    } else {
      this.slides = 0
    }
  },
  methods: {
    ...mapActions({
      updateAccount: 'account/updateAccount',
      addToast: 'toasts/addToast'
    }),
    allowOnlyNumbers (input) {
      if (isNaN(input)) {
        this.$refs.sharedCapital.reset()
        this.rules.charTpe = !isNaN(input)
      }
    },
    formatDate (date) {
      const [year, month, day] = date.split('-')
      this.accountData.date_of_birth = `${day}/${month}/${year}`
    },
    async updateAccountData () {
      let blankFields = []
      if (this.account.accountType === 'lawyer') {
        Object.keys(this.form).forEach(f => {
          this.$refs[f].validate(true)
          if (this.$refs[f].validate(true) === false) {
            blankFields.push(f)
          }
        })
      }
      if (this.account.accountType === 'individual') {
        Object.keys(this.individualClientForm).forEach(f => {
          this.$refs[f].validate(true)
          if (this.$refs[f].validate(true) === false) {
            blankFields.push(f)
          }
        })
      }
      if (this.account.accountType === 'company') {
        Object.keys(this.companyClientForm).forEach(f => {
          this.$refs[f].validate(true)
          if (this.$refs[f].validate(true) === false) {
            blankFields.push(f)
          }
        })
        this.accountData.selectedCurrency = this.selectedCurrency || this.Currencies.EUR
      }
      if (!blankFields.length) {
        try {
          this.accountData.email = this.account.email
          this.accountData.requiredFieldsFilled = true
          await this.updateAccount({ accountData: this.accountData })
          this.welcomeDialog = false
          this.addToast({
            title: this.$t('profile|updated_sucessfully'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (error) {
          this.addToast({
            title: this.$t('profile|update_failed'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      } else {
        this.addToast({
          title: this.$t('profile|empty_field'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async skipProfileEdit () {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('profile|confirm_skip'),
        type: 'warning'
      })
      if (!res) return
      this.welcomeDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
