<template>
  <div class="app-wrapper">
    <v-overlay
      absolute
      :value="loading"
      opacity="0.46"
      class="full-screen"
      text-xs-center
    >
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          :size="50"
          color="white"
          indeterminate
        />
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="28px"
          width="auto"
          class="mt-6"
        >
        <span
          v-for="(message, index) in messages"
          :key="index"
          class="mt-4 ml-2"
          style="font-size: 15px;"
        >
          {{ message }}
        </span>
      </div>
    </v-overlay>
    <router-view v-if="!loading" />
    <WelcomeDialog
      ref="WelcomeDialog"
    />
    <AddClientDialog />
    <AddCaseModel />
    <AddCaseDialog />
    <AddCaseFromCaseModel />
    <AddTemplateDialog />
    <AddTemplateToWorkbenchDialog />
    <Questionairre />
    <OverviewDrawer />
    <DocumentsDialog />
    <AddDocumentDialog />
    <AssignDocumentDialog />
    <ExportDocumentsDialog />
    <InfoDialogCustomSuggestion />
    <CreateGroup />
    <ShareCaseModel />
    <ShareDocuments />
    <ShareTemplates />
    <FileView global />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import WelcomeDialog from '@/components/dialogs/WelcomeDialog.vue'
import AddClientDialog from '@/components/dialogs/AddClient.vue'
import AddCaseDialog from '@/components/dialogs/AddCase.vue'
import AddCaseModel from '@/components/dialogs/AddCaseModel.vue'
import AddCaseFromCaseModel from '@/components/dialogs/AddCaseFromCaseModel.vue'
import AddTemplateDialog from '@/components/dialogs/AddTemplate.vue'
import AddTemplateToWorkbenchDialog from '@/components/dialogs/AddTemplateToWorkbench.vue'
import Questionairre from '@/components/dialogs/Questionairre.vue'
import OverviewDrawer from '@/components/drawers/OverviewDrawer.vue'
import AssignDocumentDialog from '@/components/dialogs/AssignDocument.vue'
import AddDocumentDialog from '@/components/dialogs/AddDocument.vue'
import ExportDocumentsDialog from '../components/dialogs/ExportDocumentsDialog.vue'
import DocumentsDialog from '@/components/dialogs/DocumentsDialog.vue'
import InfoDialogCustomSuggestion from '@/components/dialogs/InfoDialogCustomSuggestion'
import CreateGroup from '@/components/dialogs/CreateGroup.vue'
import ShareDocuments from '@/components/dialogs/ShareDocuments.vue'
import ShareTemplates from '@/components/dialogs/ShareTemplates.vue'
import FileView from '@/components/dialogs/FileView.vue'
import ShareCaseModel from '@/components/dialogs/ShareCaseModel.vue'

export default {
  components: {
    WelcomeDialog,
    AddClientDialog,
    AddCaseDialog,
    AddCaseModel,
    AddCaseFromCaseModel,
    AddTemplateDialog,
    AddTemplateToWorkbenchDialog,
    Questionairre,
    OverviewDrawer,
    AssignDocumentDialog,
    AddDocumentDialog,
    ExportDocumentsDialog,
    InfoDialogCustomSuggestion,
    DocumentsDialog,
    CreateGroup,
    ShareDocuments,
    ShareTemplates,
    FileView,
    ShareCaseModel
  },
  data () {
    return {
      loading: true,
      messages: []
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    })
  },
  created () {
    this.fetchInitialData()
    EventBus.$on('new-group-subscription', this.subscribeForGroupUpdates)
    EventBus.$on('subscribe-for-company-updates', this.onSubscribeForCompanyUpdates)
  },
  beforeDestroy () {
    EventBus.$off('new-group-subscription', this.subscribeForGroupUpdates)
    EventBus.$off('subscribe-for-company-updates', this.onSubscribeForCompanyUpdates)
  },
  methods: {
    ...mapMutations({
      toggleLoadingData: 'app/toggleLoadingData',
      toggleDrawer: 'app/toggleDrawer',
      toggleToolbar: 'app/toggleToolbar'
    }),
    ...mapActions({
      fetchAccount: 'account/fetchAccount',
      fetchSettings: 'settings/fetchSettings',
      fetchClients: 'clients/fetchClients',
      fetchCompanyClients: 'companyClients/fetchCompanyClients',
      fetchGroupClients: 'groupClients/fetchGroupClients',
      fetchCases: 'cases/fetchCases',
      fetchSharedWithMeCases: 'cases/fetchSharedWithMeCases',
      fetchCompanyCases: 'companyCases/fetchCompanyCases',
      fetchGroupCases: 'groupCases/fetchGroupCases',
      fetchTemplates: 'templates/fetchTemplates',
      fetchCompanyTemplates: 'companyTemplates/fetchCompanyTemplates',
      fetchGroupTemplates: 'groupTemplates/fetchGroupTemplates',
      fetchLavviraTemplates: 'templates/fetchLavviraTemplates',
      fetchCaseForms: 'caseForms/fetchCaseForms',
      fetchLavviraCaseForms: 'caseForms/fetchLavviraCaseForms',
      fetchCompanyCaseForms: 'companyCaseForms/fetchCompanyCaseForms',
      fetchGroupCaseForms: 'groupCaseForms/fetchGroupCaseForms',
      getMyClientAccountAsClient: 'clients/getMyClientAccountAsClient',
      getDocuments: 'documents/getDocuments',
      getSharedWithMeDocuments: 'documents/getSharedWithMeDocuments',
      getCompanyDocuments: 'companyDocuments/getCompanyDocuments',
      getGroupDocuments: 'groupDocuments/getGroupDocuments',
      getDocumentFolders: 'documents/getDocumentFolders',
      getSharedWithMeFolders: 'documents/getSharedWithMeFolders',
      getCompanyFolders: 'companyDocuments/getCompanyFolders',
      getGroupFolders: 'groupDocuments/getGroupFolders',
      setCompany: 'company/setCompany',
      removeInvitation: 'account/removeInvitation',
      removeCompanyOrGroupInvitation: 'company/removeCompanyOrGroupInvitation',
      setNotificationsSubscription: 'account/setNotificationsSubscription',
      casesUpdatesSubscription: 'cases/casesUpdatesSubscription',
      companyCasesUpdatesSubscription: 'companyCases/companyCasesUpdatesSubscription',
      groupCasesUpdatesSubscription: 'groupCases/groupCasesUpdatesSubscription',
      companyCaseFormsUpdatesSubscription: 'companyCaseForms/companyCaseFormsUpdatesSubscription',
      groupCaseFormsUpdatesSubscription: 'groupCaseForms/groupCaseFormsUpdatesSubscription',
      templatesUpdatesSubscription: 'templates/templatesUpdatesSubscription',
      companyTemplatesUpdatesSubscription: 'companyTemplates/companyTemplatesUpdatesSubscription',
      groupTemplatesUpdatesSubscription: 'groupTemplates/groupTemplatesUpdatesSubscription',
      documentsUpdatesSubscription: 'documents/documentsUpdatesSubscription',
      companyDocumentsUpdatesSubscription: 'companyDocuments/companyDocumentsUpdatesSubscription',
      groupDocumentsUpdatesSubscription: 'groupDocuments/groupDocumentsUpdatesSubscription',
      clientsUpdatesSubscription: 'clients/clientsUpdatesSubscription',
      companyClientsUpdatesSubscription: 'companyClients/companyClientsUpdatesSubscription',
      groupClientsUpdatesSubscription: 'groupClients/groupClientsUpdatesSubscription',
      accountUpdatesSubscription: 'account/accountUpdatesSubscription',
      companyUpdatesSubscription: 'company/companyUpdatesSubscription'
    }),
    async fetchInitialData () {
      this.toggleDrawer(false)
      this.toggleToolbar(false)
      try {
        if (this.account && this.account.accountType !== 'god') {
          this.$i18n.locale = this.account.accountData.preferedLanguage
          this.messages.push(this.$t('loading|documents'))
          await this.fetchSettings()
          await this.accountUpdatesSubscription({ _id: this.account._id })
          await this.setNotificationsSubscription()
          await this.getDocuments()
          await this.getDocumentFolders({ _id: this.account._id })
          await this.getSharedWithMeDocuments({ _id: this.account._id })
          await this.getSharedWithMeFolders({ _id: this.account._id })
          await this.documentsUpdatesSubscription({ _id: this.account._id })
          const docMessIndex = this.messages.findIndex(mess => mess === this.$t('loading|documents'))
          this.messages.splice(docMessIndex, 1, this.$t('loading|documents') + this.$t('common|done'))
          this.messages.splice(docMessIndex, 1)
          this.messages.push(this.$t('loading|cases'))
          await this.fetchCases()
          // await this.fetchSharedWithMeCases({ _id: this.account._id })
          await this.casesUpdatesSubscription({ _id: this.account._id })
          const caseMessIndex = this.messages.findIndex(mess => mess === this.$t('loading|cases'))
          this.messages.splice(caseMessIndex, 1, this.$t('loading|cases') + this.$t('common|done'))
          this.messages.splice(caseMessIndex, 1)
        }

        if (this.account && this.account.accountType === 'god') {
          this.$i18n.locale = this.account.accountData.preferedLanguage
          await this.fetchSettings()
          await this.fetchTemplates()
          await this.fetchLavviraTemplates()
          await this.fetchCaseForms()
          await this.fetchLavviraCaseForms()
          await this.getDocuments()
          await this.documentsUpdatesSubscription({ _id: this.account._id })
        }

        if (this.account && this.account.accountType === 'lawyer') {
          // check if welcome dialog is needed
          if (!this.account.accountData.phone && !this.account.accountData.firstName &&
          !this.account.accountData.lastName && !this.account.accountData.nationality &&
          !this.account.accountData.address && !this.account.accountData.city && !this.account.accountData.postal_code) {
            this.$refs.WelcomeDialog.welcomeDialog = true
          }
          this.messages.push(this.$t('loading|clients'))
          await this.fetchClients()
          await this.clientsUpdatesSubscription({ _id: this.account._id })
          const clientsMessIndex = this.messages.findIndex(mess => mess === this.$t('loading|clients'))
          this.messages.splice(clientsMessIndex, 1, this.$t('loading|clients') + this.$t('common|done'))
          this.messages.splice(clientsMessIndex, 1)

          this.messages.push(this.$t('loading|templates'))
          await this.fetchTemplates()
          await this.fetchLavviraTemplates()
          const tempsMessIndex = this.messages.findIndex(mess => mess === this.$t('loading|templates'))
          this.messages.splice(tempsMessIndex, 1, this.$t('loading|templates') + this.$t('common|done'))
          this.messages.splice(tempsMessIndex, 1)
          this.messages.push(this.$t('loading|case_models'))
          await this.fetchCaseForms()
          await this.fetchLavviraCaseForms()
          this.account.invitations.forEach(invitation => {
            if (invitation.status === 'accepted' || invitation.status === 'declined') {
              const _id = this.account._id
              const payload = {
                invitation,
                account: true
              }
              this.removeInvitation({ _id, payload })
            }
          })
          const caseModelsMessIndex = this.messages.findIndex(mess => mess === this.$t('loading|case_models'))
          this.messages.splice(caseModelsMessIndex, 1, this.$t('loading|case_models') + this.$t('common|done'))
          this.messages.splice(caseModelsMessIndex, 1)
        }

        if (this.account && this.account.companyId) {
          this.messages.push(this.$t('loading|members'))
          await this.setCompany()
          await this.companyUpdatesSubscription({ _id: this.account.companyId })
          await this.fetchCompanyClients({ _id: this.account.companyId })
          await this.companyClientsUpdatesSubscription({ _id: this.account.companyId })
          await this.getCompanyDocuments({ _id: this.account.companyId })
          await this.getCompanyFolders({ _id: this.account.companyId })
          await this.companyDocumentsUpdatesSubscription({ _id: this.account.companyId })
          await this.fetchCompanyCases({ _id: this.account.companyId })
          await this.companyCasesUpdatesSubscription({ _id: this.account.companyId })
          await this.fetchCompanyTemplates({ _id: this.account.companyId })
          await this.templatesUpdatesSubscription({ _id: this.account.companyId })
          await this.companyTemplatesUpdatesSubscription({ _id: this.account.companyId })
          await this.fetchCompanyCaseForms({ _id: this.account.companyId })
          await this.companyCaseFormsUpdatesSubscription({ _id: this.account.companyId })

          this.company.invitations.forEach(invitation => {
            if (invitation.status === 'accepted' || invitation.status === 'declined') {
              const _id = this.company._id
              const payload = {
                invitation,
                company: true
              }
              this.removeCompanyOrGroupInvitation({ _id, payload })
            }
          })
          const companyMessIndex = this.messages.findIndex(mess => mess === this.$t('loading|members'))
          this.messages.splice(companyMessIndex, 1, this.$t('loading|members') + this.$t('common|done'))
          this.messages.splice(companyMessIndex, 1)
        }

        if (this.account && this.account.companyId && this.company) {
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            group.invitations.forEach(invitation => {
              if (invitation.status === 'accepted' || invitation.status === 'declined') {
                const _id = group._id
                const payload = {
                  invitation,
                  group: true
                }
                this.removeCompanyOrGroupInvitation({ _id, payload })
              }
            })
            const indexInGroups = group.groupMembers.findIndex(m => m._id === this.account._id)
            if (indexInGroups !== -1) {
              const _id = group._id
              this.messages.push(this.$t('loading|groups'))
              await this.fetchGroupClients({ _id })
              await this.groupClientsUpdatesSubscription({ _id })
              await this.getGroupDocuments({ _id })
              await this.groupDocumentsUpdatesSubscription({ _id })
              await this.getGroupFolders({ _id })
              await this.fetchGroupCases({ _id })
              await this.groupCasesUpdatesSubscription({ _id })
              await this.fetchGroupTemplates({ _id })
              await this.groupTemplatesUpdatesSubscription({ _id })
              await this.fetchGroupCaseForms({ _id })
              await this.groupCaseFormsUpdatesSubscription({ _id })
              const companyMessIndex = this.messages.findIndex(mess => mess === this.$t('loading|groups'))
              this.messages.splice(companyMessIndex, 1, this.$t('loading|groups') + this.$t('common|done'))
              this.messages.splice(companyMessIndex, 1)
            }
          }
        }

        // check if client account => account types are company/individual/lawyer
        if (this.account && (this.account.accountType === 'company' || this.account.accountType === 'individual')) {
          const _id = this.account._id
          await this.fetchAccount({ _id })
          if (this.account.accountData && !this.account.accountData.requiredFieldsFilled) {
            this.$refs.WelcomeDialog.welcomeDialog = true
          }
          await this.getMyClientAccountAsClient({ _id })
          this.account.invitations.forEach(invitation => {
            if (invitation.status === 'accepted' || invitation.status === 'declined') {
              const _id = this.account._id
              const payload = {
                invitation,
                account: true
              }
              this.removeInvitation({ _id, payload })
            }
          })
        }
        this.toggleLoadingData(false)
      } catch (e) {
        this.$router.push('/login')
        console.error(e, 'error in promise all')
      } finally {
        this.loading = false
        this.messages = []
        this.toggleDrawer(true)
        this.toggleToolbar(true)
      }
    },
    async subscribeForGroupUpdates (_id) {
      const group = this.company.groups.find(gr => gr._id === _id)
      const indexInGroups = group.groupMembers.findIndex(m => m._id === this.account._id)
      if (indexInGroups !== -1) {
        await this.fetchGroupClients({ _id })
        await this.groupClientsUpdatesSubscription({ _id })
        await this.getGroupDocuments({ _id })
        await this.groupDocumentsUpdatesSubscription({ _id })
        await this.fetchGroupCases({ _id })
        await this.groupCasesUpdatesSubscription({ _id })
        await this.fetchGroupTemplates({ _id })
        await this.groupTemplatesUpdatesSubscription({ _id })
        await this.fetchGroupCaseForms({ _id })
        await this.groupCaseFormsUpdatesSubscription({ _id })
      }
    },
    async onSubscribeForCompanyUpdates (companyId) {
      await this.companyUpdatesSubscription({ _id: companyId })
      await this.fetchCompanyClients({ _id: companyId })
      await this.companyClientsUpdatesSubscription({ _id: companyId })
      await this.getCompanyDocuments({ _id: companyId })
      await this.companyDocumentsUpdatesSubscription({ _id: companyId })
      await this.fetchCompanyCases({ _id: companyId })
      await this.companyCasesUpdatesSubscription({ _id: companyId })
      await this.fetchCompanyTemplates({ _id: companyId })
      await this.companyTemplatesUpdatesSubscription({ _id: companyId })
      await this.fetchCompanyCaseForms({ _id: companyId })
      await this.companyCaseFormsUpdatesSubscription({ _id: companyId })
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-wrapper {
    height: 100%;
    width: 100%;
  }
  .centered-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
