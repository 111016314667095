<template>
  <v-container
    v-if="template"
    fluid
    style="height: 100%; overflow-y: hidden;"
  >
    <v-row no-gutters>
      <v-icon
        class="x-icon-position"
        @click="$emit('close-drawer')"
      >
        mdi-arrow-collapse-right
      </v-icon>
      <v-col
        cols="9"
        class="pa-4"
      >
        <div
          class="editor-history pa-6"
        >
          <editor-content
            :editor="editor"
          />
        </div>
      </v-col>
      <v-col
        cols="3"
        class="mt-6"
      >
        <span v-if="!filteredHistory.length">No other versions</span>
        <v-list
          v-else
          tree-line
          style="max-height: 700px; overflow-y: auto;"
        >
          <v-list-group
            :value="true"
          >
            <template v-slot:activator>
              <v-list-item-title>Versions</v-list-item-title>
            </template>
            <v-list-item
              v-for="(item, i) in filteredHistory"
              :key="i"
              @click="showVersion(item)"
            >
              <v-list-item-avatar
                size="18"
              >
                <v-icon
                  class="primary"
                  dark
                  x-small
                >
                  mdi-clipboard-text
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.createdAt" />

                <v-list-item-subtitle v-text="item.createdAtTime" />

                <v-list-item-subtitle
                  v-if="i === 0"
                  class="error--text"
                >
                  actual version
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="grey lighten-1"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="restoreVersion(item)"
                    >
                      mdi-backup-restore
                    </v-icon>
                  </template>
                  <span>Restore this version</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import StarterKit from '@tiptap/starter-kit'
import HashTag from '@/pages/TemplateEditor/HashTag/HashTag'
import Comment from '@/pages/TemplateEditor/Comment/Comment'
import FontSize from '@/pages/TemplateEditor/FontSize/FontSize'
import CustomTable from '@/pages/TemplateEditor/CustomTable/CustomTable.js'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import { Color } from '@/tiptap/extensions/colorStyle'
import Heading from '@tiptap/extension-heading'
import Typography from '@tiptap/extension-typography'
import Paragraph from '@tiptap/extension-paragraph'
import Underline from '@tiptap/extension-underline'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
import CharacterCount from '@tiptap/extension-character-count'
import { DropCap } from '@/pages/TemplateEditor/DropCap/dropCap'
import Collaboration from '@tiptap/extension-collaboration'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'

import { mapState } from 'vuex'
import moment from 'moment'
const createdAtFormat = date => moment(date).format('DD/MM/YYYY, h:mm:ss a')

export default {
  components: {
    EditorContent
  },
  props: {
    actualCase: {
      type: Object,
      default: null
    },
    actualCaseModel: {
      type: Object,
      default: null
    },
    template: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    inTemplate: {
      type: Boolean,
      default: false
    },
    inCase: {
      type: Boolean,
      default: false
    },
    inCaseModel: {
      type: Boolean,
      default: false
    }
  },

  data: (instance) => {
    return {
      editor: null,
      timeStamp: instance.template.templateDataHistoryRef.templateDataHistory[instance.template.templateDataHistoryRef.templateDataHistory.length - 1].time,
      provider: null,
      ydoc: null
    }
  },

  computed: {
    ...mapState({
      token: state => state.auth.token
    }),
    filteredHistory () {
      return this.template.templateDataHistoryRef.templateDataHistory.map((object) => {
        const itemLength = createdAtFormat(object.time).length
        return {
          // json: object.json,
          createdAt: createdAtFormat(object.time).slice(0, itemLength - 12),
          createdAtTime: createdAtFormat(object.time).slice(12, itemLength),
          rawTimestamp: object.time
        }
      }).reverse()
    }
  },
  mounted () {
    this.makeSetup()
  },

  beforeDestroy () {
    this.editor.destroy()
    this.timeStamp = null
    this.editor.destroy()
    this.ydoc = null
    this.provider = null
  },

  methods: {
    showVersion (contentObject) {
      this.timeStamp = contentObject.rawTimestamp
      this.editor.destroy()
      this.ydoc = null
      this.provider = null
      this.makeSetup()
    },
    restoreVersion (contentObject) {
      // TO BE REFACTORED => NEW TEMPLATE DATA HYSTORY/TRACK CHANGES
      // const json = this.editor.getJSON()
      // EventBus.$emit('restore-template-version', json)
      this.$emit('close-drawer')
      this.editor.destroy()
      this.timeStamp = null
      this.editor.destroy()
      this.ydoc = null
      this.provider = null
    },
    makeSetup () {
      let room
      this.ydoc = new Y.Doc()
      const token = this.token
      const url = process.env.VUE_APP_COLLABORATION_PATH
      if (this.inTemplate) {
        room = `get-history/${this.timeStamp}/${this.template._id}`
      } else if (this.inCase) {
        room = `getCaseHistory/${this.timeStamp}/${this.actualCase._id}/${this.workbench._id}/${this.template._id}`
      } else if (this.inCaseModel) {
        room = `getModelHistory/${this.timeStamp}/${this.actualCaseModel._id}/${this.workbench._id}/${this.template._id}`
      }
      this.provider = new WebsocketProvider(url, room, this.ydoc, { params: { Authorization: `Bearer ${token}` }, resyncInterval: 5000 })
      this.editor = new Editor({
        extensions: [
          Collaboration.configure({
            document: this.ydoc,
            field: 'doc'
          }),
          Document,
          Text,
          Bold,
          Italic,
          Superscript,
          Subscript,
          CharacterCount.configure({
            mode: 'nodeSize'
          }),
          StarterKit.configure({
            // history should be switched off because of ydoc (ydoc has own history)
            // the other moduls are imported separetly because of configuration and bugs
            history: false,
            paragraph: false,
            heading: false,
            bold: false,
            document: false,
            italic: false,
            text: false
          }),
          Heading.configure({
            levels: [1, 2, 3, 4, 5, 6]
          }),
          TextAlign.configure({
            types: ['heading', 'paragraph'],
            alignments: ['left', 'center', 'right', 'justify']
          }),
          TextStyle,
          FontFamily,
          FontSize,
          Color,
          DropCap,
          Typography,
          CustomTable,
          TableRow,
          TableCell.extend({
            addAttributes () {
              return {
                ...this.parent?.(),
                backgroundColor: {
                  default: '#FFFFFF',
                  // Customize the HTML parsing (for example, to load the initial content)
                  parseHTML: element => element.getAttribute('table-background-color'),
                  // … and customize the HTML rendering.
                  renderHTML: attributes => {
                    return {
                      'table-background-color': attributes.backgroundColor,
                      style: `background-color: ${attributes.backgroundColor}`
                    }
                  }
                },
                border: {
                  default: '2px solid #ced4da;',
                  // Customize the HTML parsing (for example, to load the initial content)
                  parseHTML: element => element.getAttribute('border'),
                  // … and customize the HTML rendering.
                  renderHTML: attributes => {
                    return {
                      'border': attributes.border,
                      style: `border: ${attributes.border}`
                    }
                  }
                },
                colwidth: {
                  default: null,
                  parseHTML: (element) => {
                    const colwidth = element.getAttribute('colwidth')
                    const value = colwidth ? [parseInt(colwidth, 10)] : null
                    return value
                  },
                  renderHTML: (attributes) => {
                    return {
                      colwidth: attributes.colwidth,
                      style: attributes.colwidth
                        ? `width: ${attributes.colwidth}px`
                        : null
                    }
                  }
                }
              }
            }
          }),
          TableHeader.extend({
            addAttributes () {
              return {
                ...this.parent?.(),
                colwidth: {
                  default: null,
                  parseHTML: (element) => {
                    const colwidth = element.getAttribute('colwidth')
                    const value = colwidth ? [parseInt(colwidth, 10)] : null
                    return value
                  },
                  renderHTML: (attributes) => {
                    return {
                      colwidth: attributes.colwidth,
                      style: attributes.colwidth
                        ? `width: ${attributes.colwidth}px`
                        : null
                    }
                  }
                }
              }
            }
          }),
          Paragraph.extend({
            addAttributes () {
              return {
                color: {
                  default: null,
                  // Take the attribute values
                  renderHTML: attributes => {
                  // … and return an object with HTML attributes.
                    return {
                      style: `color: ${attributes.color}`
                    }
                  }
                },
                lineHeight: {
                  default: '1.2',
                  renderHTML: attributes => {
                  // … and return an object with HTML attributes.
                    return {
                      style: `line-height: ${attributes.lineHeight}`
                    }
                  }
                }
              }
            }
          }),
          Underline.configure({
            HTMLAttributes: {
              class: 'underline'
            }
          }),
          Comment,
          HashTag
        ]
      })
      this.editor.setOptions({ editable: false })
      this.provider.connect()
    }
  }
}
</script>

<style scoped>
.x-icon-position {
  position: absolute;
  top: 5px;
  right: 15px;
}
.editor-history {
  height: 84vh;
  background: #fffffe;
  border: 1px solid #b1afaf;
  border-radius: 0.2rem;
  margin: 1rem 0;
  position: relative;
  overflow-y: auto;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 2px 4px 8px 5px rgba(0, 0, 0, 0.2);
}
</style>
