<template>
  <v-container
    fluid
    fill-height
    class="pa-2 d-flex flex-nowrap"
  >
    <div
      v-if="loading"
      style="width: 100%; height: 100%;"
    >
      <v-overlay
        absolute
        opacity="0.78"
        :value="loading"
      >
        <v-progress-circular
          :size="50"
          color="white"
          indeterminate
        />
        <div style="position: relative; top: 15px; right: 40%;">
          <img
            src="@/assets/Lavirra_logo_SVG.svg"
            height="28px"
            width="auto"
          >
        </div>
      </v-overlay>
    </div>
    <v-card
      v-else
      class="flex-grow-1 mr-2"
      style="position: relative; height: 100%;"
      flat
    >
      <template v-if="currentCase">
        <span class="title">{{ $t('cases|case_overview') }}</span>
        <v-icon
          size="20"
          color="primary"
          class="close-icon-position"
          @click="$emit('close-drawer')"
        >
          mdi-arrow-collapse-right
        </v-icon>
        <v-row
          dense
        >
          <v-col
            cols="12"
            sm="5"
          >
            <v-card
              flat
            >
              <v-card-title>
                <v-hover
                  v-slot="{ hover }"
                >
                  <div
                    class="mt-n2"
                    style="min-width: 100%"
                  >
                    <input
                      v-model="currentCase.caseName"
                      class="pl-2 ml-n2 rounded input"
                      style="white-space:nowrap; text-overflow: ellipsis; min-width: 100%"
                      :style="hover && !flatFields.includes(currentCase.caseName) ? 'background-color: #eee; text-overflow: initial;' : flatFields.includes(currentCase.caseName) ? 'background-color: #CFD8DC' : ''"
                      @focus="flatFields.push(currentCase.caseName)"
                      @blur="updateCaseName(currentCase.caseName); flatFields.splice(0, 1)"
                    >
                  </div>
                </v-hover>
              </v-card-title>
              <v-card-text>
                <div>
                  {{ $t('clients|client') }}:
                  <template v-if="currentCase && currentCase.client && !currentCase.client.realClient">
                    <span v-if="currentCase.client.clientType === 'individual'">{{ currentCase.client.clientData.given_names ? currentCase.client.clientData.given_names : 'not added' }} {{ currentCase.client.clientData.surname ? currentCase.client.clientData.surname : 'not added' }}</span>
                    <span v-if="currentCase.client.clientType === 'company'">{{ currentCase.client.clientData.company_name }}</span>
                  </template>
                  <template v-if="currentCase && currentCase.client && currentCase.client.realClient">
                    <span v-if="currentCase.client.clientType === 'individual'">{{ currentCase.client.clientData.given_names ? currentCase.client.clientData.given_names : 'not added' }} {{ currentCase.client.clientData.surname ? currentCase.client.clientData.surname : 'not added' }}</span>
                    <span v-if="currentCase.client.clientType === 'company'">{{ currentCase.client.clientData.company_name }}</span>
                  </template>
                  <template v-if="currentCase && !currentCase.client">
                    <v-icon
                      small
                      color="primary"
                      class="ml-1"
                      @click="assignNewClient()"
                    >
                      mdi-account-plus
                    </v-icon>
                  </template>
                </div>
                <div>
                  {{ $t('common|date_created') }}: {{ currentCase.createdAt | date }}
                </div>
                <div
                  v-if="account.accountType === 'lawyer'"
                >
                  {{ $t('common|date_updated') }}: {{ currentCase.updatedAt | dateTime }}
                </div>
                <div>
                  {{ $t('common|created_by') }}: {{ currentCase.createdBy.email }}
                </div>
                <CaseProgress
                  :status="currentCase.status"
                  :current-case="currentCase"
                  :cols-progress-bar="8"
                  :cols-title="3"
                  :account="account"
                  style="flex-grow: 1; width: auto; max-width: 350px"
                  @update-progress="updateProgress"
                  @open-info-progress-dialog="openInfoProgressDialog"
                />

                <InfoProgressDialog
                  ref="InfoProgressDialog"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-card
              flat
              height="208"
              style="overflow-y: auto;"
            >
              <v-toolbar
                dense
                flat
                height="35"
                class="mt-n4 mb-3"
                style="position: sticky; top: 0px; z-index: 1;"
              >
                <v-subheader
                  class="ml-n7 mt-3"
                >
                  {{ $t('cases|participants') }}
                </v-subheader>
              </v-toolbar>
              <v-card-text
                class="px-2 mt-n3"
              >
                <div
                  v-for="(participant, index) in sharedWithMembers"
                  :key="index"
                  class="mx-auto my-1"
                >
                  <v-chip
                    color="primary"
                    outlined
                    x-small
                  >
                    <v-avatar
                      size="18px"
                      class="ml-n3 mr-1"
                    >
                      <img
                        v-if="participant && participant.avatar"
                        alt="Avatar"
                        :src="`${cfg.baseURL}downloadAvatar/${participant._id}/${participant.avatar.raw.filename}`"
                      >
                      <v-avatar
                        v-else
                      >
                        <v-icon
                          color="primary"
                          small
                        >
                          mdi-account
                        </v-icon>
                      </v-avatar>
                    </v-avatar>
                    <span
                      class="text-truncate"
                    >
                      {{ participant.participantName }}
                    </span>
                  </v-chip>
                </div>
              </v-card-text>
              <v-toolbar
                dense
                flat
                height="35"
                style="position: sticky; bottom: 0px; z-index: 1;"
              >
                <v-chip
                  v-if="account.accountType === 'lawyer'"
                  outlined
                  rounded
                  color="primary"
                  small
                  class="ml-n2 mt-n2"
                  @click="openShareCaseDialog"
                >
                  <v-icon
                    left
                    x-small
                  >
                    mdi-plus
                  </v-icon>
                  {{ $t('actions|add_new') }}
                </v-chip>
                <v-chip
                  v-if="account.accountType === 'lawyer'"
                  outlined
                  rounded
                  color="primary"
                  small
                  class="ml-2 mt-n2"
                  :disabled="currentCase.sharedWith.accounts.length + currentCase.sharedWith.company.length + currentCase.sharedWith.groups.length === 1"
                  @click="removeCase(currentCase)"
                >
                  <v-icon
                    left
                    small
                    style="position: absolute; left 6px;"
                  >
                    mdi-minus
                  </v-icon>
                  <span class="ml-3">{{ $t('actions|delete') }}</span>
                </v-chip>
              </v-toolbar>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-card
              flat
              height="208"
              class="pt-3"
            >
              <v-card-text>
                <v-row
                  justify="end"
                >
                  <v-menu
                    v-model="statusMenu"
                    offset-y
                    :disabled="account.accountType !== 'lawyer'"
                    @click.native.stop.prevent
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="account.accountType === 'lawyer'"
                        color="primary"
                        outlined
                        dark
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          small
                          left
                        >
                          {{ getTheRightIcon(currentCase.status) }}
                        </v-icon>
                        {{ getTranslatedStatus(currentCase.status) }}
                        <v-icon
                          v-if="account.accountType === 'lawyer'"
                          class="ml-1"
                          small
                        >
                          {{ icons.arrowDown }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="status in caseStatuses"
                        :key="status.value"
                        :active-class="status.value === currentCase.status ? 'active-item' : ''"
                        :class="{active: status.value === currentCase.status}"
                        @click.stop="changeStatus(currentCase, status); statusMenu = false"
                      >
                        <v-icon
                          class="ml-n2 mr-2 mt-1"
                          size="18"
                          :color="status.color"
                        >
                          {{ status.icon }}
                        </v-icon>
                        <v-list-item-title>{{ getTranslatedStatus(status.text) }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div v-if="currentCase.pinned.includes(account._id)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          icon
                          small
                          class="mt-n1"
                          v-on="on"
                          @click.stop="removePinFromDashboard(currentCase)"
                        >
                          <v-icon>
                            mdi-pin
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('actions|remove_from_pinboard') }}</span>
                    </v-tooltip>
                  </div>
                  <div v-else>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          icon
                          small
                          class="mt-n1"
                          v-on="on"
                          @click.stop="pinToDashboard(currentCase)"
                        >
                          <v-icon>
                            mdi-pin-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('actions|add_to_pinboard') }}</span>
                    </v-tooltip>
                  </div>
                </v-row>
                <v-row
                  dense
                  justify="end"
                  class="mt-4"
                >
                  <div
                    v-if="account.accountType === 'lawyer'"
                    class="d-flex flex-column"
                  >
                    <v-btn
                      rounded
                      outlined
                      color="primary"
                      x-small
                      width="100%"
                      max-width="250px"
                      class="mb-1"
                      @click="exportCase(currentCase)"
                    >
                      <v-icon
                        x-small
                        left
                        class="icon-case-action-position"
                      >
                        mdi-download
                      </v-icon>
                      {{ $t('actions|export_case') }}
                    </v-btn>
                    <v-btn
                      rounded
                      outlined
                      color="primary"
                      x-small
                      width="100%"
                      max-width="250px"
                      class="mb-1"
                      @click="generateCaseModel"
                    >
                      <v-icon
                        x-small
                        left
                        class="icon-case-action-position"
                      >
                        mdi-briefcase-edit
                      </v-icon>
                      <span class="ml-5">{{ $t('actions|generate_case_model') }}</span>
                    </v-btn>
                    <v-btn
                      rounded
                      outlined
                      color="primary"
                      x-small
                      width="100%"
                      max-width="250px"
                      class="mb-1"
                      @click="openCaseTimeline"
                    >
                      <v-icon
                        x-small
                        left
                        class="icon-case-action-position"
                      >
                        mdi-timeline
                      </v-icon>
                      {{ $t('cases|case_timeline') }}
                    </v-btn>
                    <!-- <v-btn
                      v-if="account.accountType === 'lawyer'"
                      rounded
                      outlined
                      color="primary"
                      x-small
                      width="100%"
                      max-width="250px"
                      class="mb-1"
                      @click="generalQDialog = true"
                    >
                      <v-icon
                        x-small
                        left
                      >
                        mdi-help-box
                      </v-icon>
                      All Questionnaires
                    </v-btn> -->
                  <!-- <GeneralQuestionairreCardCases
                        v-if="account.accountType === 'lawyer'"
                        :height="'100'"
                        :max-width="'250px'"
                        @open-general-questionairre="generalQDialog = true"
                      /> -->
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="d-flex align-center pa-2">
          <v-tabs
            v-model="selectedWbTab"
            active-class="white"
            :slider-color="currentCase.workbenches.length === 1 ? 'white' : 'accent'"
            next-icon="mdi-arrow-right"
            prev-icon="mdi-arrow-left"
            show-arrows
            grow
            style="max-width: 90%; overflow: auto;"
            height="35"
          >
            <v-tab
              v-for="workbench in currentCase.workbenches"
              :key="workbench._id"
              :name="workbench.name"
              :style="currentCase.workbenches.length === 1 ? 'color: black; opacity: 1.0; pointer-events: none;' : ''"
              class="tabs-text"
            >
              {{ workbench.name }}
            </v-tab>
          </v-tabs>
          <v-icon
            v-if="account.accountType === 'lawyer'"
            color="primary"
            class="mx-2"
            @click.prevent="addWorkbench(selectedWbTab)"
          >
            mdi-plus
          </v-icon>
          <!-- Edit Workbenches && Add Workbench Menu -->
          <v-menu
            v-if="account.accountType === 'lawyer'"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-dots-horizontal
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in workbenchMenuItems"
                :key="index"
                @click="triggerAction(item.action)"
              >
                <v-icon
                  small
                >
                  {{ item.icon }}
                </v-icon>
                <v-list-item-title class="ml-2">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-card
          flat
          :min-height="(workbenchHeight / 2) + 'px'"
          class="pa-0 ma-0"
          style="overflow-y: auto"
        >
          <v-tabs-items v-model="selectedWbTab">
            <v-tab-item
              v-for="workbench in currentCase.workbenches"
              :key="workbench._id"
            >
              <WorkbenchViewDrawerOverview
                :workbench-drawer-overview="workbench"
                :case-data-drawer-overview="currentCase"
                :workbench-height="workbenchHeight"
                @close-drawer="$emit('close-drawer')"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <v-card-actions class="my-4 ml-4">
          <v-btn
            outlined
            rounded
            small
            color="primary"
            link
            :to="`/cases/${currentCase._id}`"
            @click="$emit('close-drawer')"
          >
            <v-icon
              color="primary"
              small
              class="mr-1"
            >
              mdi-open-in-new
            </v-icon>
            {{ $t('actions|open') }}
          </v-btn>
          <v-spacer />
          <v-btn
            outlined
            rounded
            small
            color="primary"
            class="mr-n5"
            @click.stop="getCaseSharedWithLength(currentCase) <= 1 ? deleteCase(currentCase, 'from-case-overview') : removeCase(currentCase)"
          >
            <v-icon
              color="primary"
              small
              class="mr-1"
            >
              mdi-delete
            </v-icon>
            {{ $t('actions|delete_case') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
    <!-- <v-dialog
      v-model="generalQDialog"
      keydown
      max-width="1200px"
      transition="dialog-bottom-transition"
      style="position: relative;"
    >
      <GeneralQuestionnaireForm
        v-if="account.accountType === 'lawyer'"
        :key="generalQDialog"
        :template-data="allCurrentTemplateData"
        :workbench="actualWorkbench"
        :current-case="currentCase"
        :case-id="currentCase._id"
        :options="{
          inCase: true
        }"
        @close="generalQDialog = false"
        @save="$emit('save-template')"
        @addField="$emit('add-field')"
        @updateField="$emit('update-field')"
        @deleteField="$emit('delete-field')"
        @closeTemplate="$emit('closeTemplate')"
        @update-case-questionairre="updateQuestionnaire"
      />
    </v-dialog> -->
    <EditCaseWorkbenches
      v-if="currentCase"
      v-model="editWorkbenchesDialog"
      :workbenches="currentCase.workbenches"
      @update="updateWorkbenches"
      @delete="deleteWorkbench"
    />
    <GeneralQuestionnaireFinalForm
      v-model="fullQuestionnaireFormDialog"
      :workbench="actualWorkbench"
      @updateQuestionnaire="updateQuestionnaire"
    />
    <ShareCase
      ref="ShareCase"
      :current-case="currentCase"
    />
    <AddCaseModel
      ref="AddCaseModel"
      :case-model-data="caseModelData"
      :from-case="true"
    />
    <CaseTimeline
      ref="CaseTimeline"
      :current-case="currentCase"
    />
    <NewCaseClientDialog
      ref="NewCaseClientDialog"
      @add-new-client="addNewClient"
    />
    <Editor
      v-show="false"
      ref="Editor"
    />
  </v-container>
</template>

<script>
import { caseStatuses } from '@/utils/index'
import { saveAs } from 'file-saver'
import {
  mapState,
  mapActions
} from 'vuex'
import {
  mdiPlus,
  mdiTrashCanOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiPinOutline,
  mdiFile,
  mdiClose,
  mdiPencil
} from '@mdi/js'
import cfg from '@/config'
import ShareCase from '../../components/dialogs/ShareCase.vue'
import CaseProgress from '../../pages/Cases/CaseProgress.vue'
import WorkbenchViewDrawerOverview from '../../pages/Cases/WorkbenchViewDrawerOverview.vue'
import CaseCardMixin from '../../pages/Cases/CaseMixins/CaseCardMixin'
import InfoProgressDialog from '../dialogs/InfoProgressDialog.vue'
import EditCaseWorkbenches from '../../pages/Cases/EditCaseWorkbenches.vue'
import GeneralQuestionnaireFinalForm from '@/components/forms/GeneralQuestionnaireFinalForm.vue'
import NewCaseClientDialog from '../../components/dialogs/NewCaseClientDialog.vue'
import AddCaseModel from '../../components/dialogs/AddCaseModel.vue'
import CaseTimeline from '../../pages/Cases/CaseTimeline.vue'
import JSZip from 'jszip'
import exportTemplate from '../../pages/TemplateEditor/exportDocx.js'
import Editor from '../../pages/TemplateEditor/Editor.vue'
// import GeneralQuestionairreCardCases from '../../pages/Cases/GeneralQuestionairreCardCases.vue'
// import GeneralQuestionnaireForm from '../forms/GeneralQuestionnaireForm.vue'

export default {
  components: {
    // GeneralQuestionairreCardCases,
    // GeneralQuestionnaireForm,
    ShareCase,
    CaseProgress,
    WorkbenchViewDrawerOverview,
    InfoProgressDialog,
    EditCaseWorkbenches,
    GeneralQuestionnaireFinalForm,
    AddCaseModel,
    CaseTimeline,
    NewCaseClientDialog,
    Editor
  },
  mixins: [CaseCardMixin],
  props: {
    currentCase: {
      type: Object,
      default: null
    },
    workbenchHeight: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      workbenchMenuItems: [
        {
          text: this.$t('actions|edit_workbenches'),
          icon: mdiPencil,
          action: 'editWorkbenchesDialog'
        },
        {
          text: this.$t('actions|add_workbench'),
          icon: mdiPlus,
          action: 'addWorkbench'
        }
      ],
      icons: {
        trash: mdiTrashCanOutline,
        arrowDown: mdiChevronDown,
        arrowUp: mdiChevronUp,
        pin: mdiPinOutline,
        file: mdiFile,
        close: mdiClose
      },
      selectedWbTab: null,
      caseStatuses,
      templateIsSeleted: false,
      accountMembers: [],
      elevationEffect: false,
      editWorkbenchesDialog: false,
      generalQDialog: false,
      flatFields: [],
      cfg,
      statusMenu: false,
      fullQuestionnaireFormDialog: false,
      caseModelData: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      account: state => state.account.account,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments,
      actualTabCases: state => state.cases.actualTabCases
    }),
    actualTemplate () {
      let actualTemps = []
      if (this.$route.params.templateId) {
        for (let i = 0; i < this.currentCase.workbenches.length; i++) {
          const wb = this.currentCase.workbenches[i]
          const actualTemp = wb.templates.find(t => t._id === this.$route.params.templateId)
          actualTemps.push(actualTemp)
        }
      }
      return actualTemps[0]
    },
    allCurrentTemplateData () {
      return this.currentCase.workbenches.reduce((acc, w) => {
        acc.push(
          ...w.templates
        )
        return acc
      }, [])
    },
    actualWorkbench () {
      let actualWb
      if (this.$route.params.workbenchId) {
        actualWb = this.currentCase.workbenches.find(w => w._id === this.$route.params.workbenchId)
      }
      return actualWb
    },
    clientDocuments () {
      let docsToReturn = []
      this.currentCase.documents.forEach(doc => {
        const mactchId = doc.sharedWith.accounts.find(acc => acc === this.currentCase.client.clientAccount._id)
        if (mactchId) {
          docsToReturn.push(doc)
        }
      })
      return docsToReturn
    },
    sharedWithMembers () {
      const arr = []
      if (this.currentCase.client && this.currentCase.client.realClient) {
        const isShared = this.currentCase.sharedWith.accounts.find(acc => acc._id === this.currentCase.client.clientAccount._id)
        if (isShared) {
          const participant = {
            _id: isShared._id,
            participantName: isShared.accountData.firstName && isShared.accountData.lastName ? isShared.accountData.firstName + ' ' + isShared.accountData.lastName : isShared.accountName,
            avatar: isShared.avatar
          }
          arr.push(participant)
        }
      }
      if (this.currentCase.sharedWith.accounts.length) {
        this.currentCase.sharedWith.accounts.forEach((acc) => {
          if (acc.accountType === 'lawyer' || acc.acountType === 'individual') {
            const participant = {
              _id: acc._id,
              participantName: acc.accountData.firstName && acc.accountData.lastName ? acc.accountData.firstName + ' ' + acc.accountData.lastName : acc.accountName,
              avatar: acc.avatar
            }
            arr.push(participant)
          }
          if (acc.accountType === 'company') {
            const participant = {
              _id: acc._id,
              participantName: acc.companyName ? acc.companyName : acc.accountName,
              avatar: acc.avatar
            }
            arr.push(participant)
          }
        })
      }
      if (this.currentCase.sharedWith.company.length) {
        this.currentCase.sharedWith.company.forEach((c) => {
          const participant = {
            participantName: c.companyData.companyName,
            avatar: null
          }
          arr.push(participant)
        })
      }
      if (this.currentCase.sharedWith.groups.length) {
        this.currentCase.sharedWith.groups.forEach((g) => {
          const participant = {
            participantName: g.groupName,
            avatar: null
          }
          arr.push(participant)
        })
      }
      return arr
    }
  },
  watch: {
    searchInput (searchInput) {
      this.searchDocuments(searchInput)
    }
  },
  methods: {
    ...mapActions({
      fetchCase: 'cases/fetchCase',
      updateCase: 'cases/updateCase',
      updateCompanyCase: 'companyCases/updateCompanyCase',
      updateGroupCase: 'groupCases/updateGroupCase',
      addCaseWorkbench: 'cases/addCaseWorkbench',
      addCompanyCaseWorkbench: 'companyCases/addCompanyCaseWorkbench',
      addGroupCaseWorkbench: 'groupCases/addGroupCaseWorkbench',
      updateCaseWorkbench: 'cases/updateCaseWorkbench',
      updateCompanyCaseWorkbench: 'companyCases/updateCompanyCaseWorkbench',
      updateGroupCaseWorkbench: 'groupCases/updateGroupCaseWorkbench',
      deleteCaseWorkbenchDocument: 'cases/deleteCaseWorkbenchDocument',
      deleteCompanyCaseWorkbenchDocument: 'companyCases/deleteCompanyCaseWorkbenchDocument',
      deleteGroupCaseWorkbenchDocument: 'groupCases/deleteGroupCaseWorkbenchDocument',
      deleteCaseWorkbench: 'cases/deleteCaseWorkbench',
      deleteCompanyCaseWorkbench: 'companyCases/deleteCompanyCaseWorkbench',
      deleteGroupCaseWorkbench: 'groupCases/deleteGroupCaseWorkbench',
      addCaseWorkbenchTemplate: 'cases/addCaseWorkbenchTemplate',
      deleteCaseWorkbenchTemplate: 'cases/deleteCaseWorkbenchTemplate',
      uploadDocument: 'documents/uploadDocument'
    }),
    openShareCaseDialog () {
      this.$refs.ShareCase.dialog = true
    },
    onSearchInput (value) {
      this.searchInput = value
    },
    async addWorkbench (tabIndex) {
      const res = await this.$dialog.prompt({
        title: this.$t('mixed|new_workbench'),
        text: this.$t('actions|enter_workbench_name'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [{
          text: this.$t('actions|submit'), color: 'primary'
        }]
      })
      if (!res) {
        this.selectedWbTab = tabIndex
        return
      }
      if (this.actualTabCases.isMyTab) {
        try {
          const payload = {
            name: res
          }
          await this.addCaseWorkbench({ _id: this.currentCase._id, payload })
        } catch (e) {
          console.error(e, 'error')
        }
      } else if (this.actualTabCases.isCompanyTab) {
        try {
          const payload = {
            name: res
          }
          await this.addCompanyCaseWorkbench({ _id: this.currentCase._id, payload })
        } catch (e) {
          console.error(e, 'error')
        }
      } else if (this.actualTabCases.isGroupTab) {
        try {
          const payload = {
            name: res
          }
          await this.addGroupCaseWorkbench({ _id: this.currentCase._id, payload })
        } catch (e) {
          console.error(e, 'error')
        }
      }
    },
    async updateCaseName (currentCaseName) {
      this.updateCase({
        _id: this.currentCase._id,
        payload: {
          payload: { caseName: currentCaseName }
        }
      })
    },
    updateProgress (progressValue) {
      this.updateCase({
        _id: this.currentCase._id,
        payload: {
          payload: { progress: progressValue }
        }
      })
    },
    async updateWorkbenches (actualWbs) {
      for (let i = 0; i < actualWbs.length; i++) {
        const wb = actualWbs[i]
        if (this.actualTabCases.isMyTab) {
          await this.updateCaseWorkbench({
            _id: this.currentCase._id,
            workbenchId: wb._id,
            payload: {
              name: wb.name
            }
          })
        } else if (this.actualTabCases.isCompanyTab) {
          await this.updateCompanyCaseWorkbench({
            _id: this.currentCase._id,
            workbenchId: wb._id,
            payload: {
              name: wb.name
            }
          })
        } else if (this.actualTabCases.isGroupTab) {
          await this.updateGroupCaseWorkbench({
            _id: this.currentCase._id,
            workbenchId: wb._id,
            payload: {
              name: wb.name
            }
          })
        }
        this.editWorkbenchesDialog = false
      }
    },
    async deleteWorkbench (workbench) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: `${this.$t('common|are_you_sure_you_want_to_delete_this_resource')}: ${workbench.name}`
      })
      if (!res) return
      if (this.actualTabCases.isMyTab) {
        await this.deleteCaseWorkbench({ _id: this.currentCase._id, workbenchId: workbench._id })
      } else if (this.actualTabCases.isCompanyTab) {
        await this.deleteCompanyCaseWorkbench({ _id: this.currentCase._id, workbenchId: workbench._id })
      } else if (this.actualTabCases.isGroupTab) {
        await this.deleteGroupCaseWorkbench({ _id: this.currentCase._id, workbenchId: workbench._id })
      }
    },
    async uploadDocumentAction (document) {
      const payload = {
        language: document.language,
        file: document.file,
        caseId: this.currentCase._id,
        clientId: this.currentCase.client._id
      }
      return this.uploadDocument(payload)
    },
    async updateQuestionnaire (questionairre) {
      const filesToUpload = questionairre.fields.filter((f) => {
        return f.fieldType === 'file-input'
      })
      const documents = []
      for (let i = 0; i < filesToUpload.length; i++) {
        const fileData = await this.uploadDocumentAction(filesToUpload[i].value[0])
        documents.push(fileData.data._id)
      }
      const payloadFields = questionairre.fields.filter((f) => {
        return f.fieldType !== 'file-input'
      })
      await this.$axios.post(`/updateQuestionnaire/${questionairre._id}`, {
        fields: payloadFields,
        documents
      })
      await this.fetchCase(this.currentCase._id)
    },
    async pinToDashboard (caseFile) {
      const payload = {
        payload: {
          setPin: this.account._id
        }
      }
      try {
        await this.updateCase({
          _id: caseFile._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_pin_to_dashboard_success'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_pin_to_dashboard_fail'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async removePinFromDashboard (caseFile) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      if (res) {
        const payload = {
          payload: {
            removePin: this.account._id
          }
        }
        try {
          await this.updateCase({
            _id: caseFile._id,
            payload
          })
          this.addToast({
            title: this.$t('message|case_removal_dashboard_success'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (e) {
          this.addToast({
            title: this.$t('error|case_removal_dashboard_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    triggerAction (actionType) {
      if (actionType === 'addWorkbench') {
        this.addWorkbench()
      }
      if (actionType === 'editWorkbenchesDialog') {
        this.editWorkbenchesDialog = true
      }
    },
    openInfoProgressDialog () {
      this.$refs.InfoProgressDialog.dialog = true
    },
    getTheRightIcon (actualStatus) {
      switch (actualStatus) {
      case 'To do':
        return this.caseStatuses.todo.icon
      case 'In progress':
        return this.caseStatuses.inprogress.icon
      case 'Done':
        return this.caseStatuses.done.icon
      default:
        return 'mdi-progress-pencil'
      }
    },
    async generateCaseModel () {
      const res = await this.$dialog.warning({
        title: `${this.$t('warning|new_case_model_from_case')}`,
        text: `${this.$t('warning|documents_exclusion_if_not_marked')}`,
        showClose: false,
        width: '750'
      })
      if (!res) return
      this.caseModelData = {
        caseModelName: this.currentCase.caseName,
        description: '',
        category: 'none',
        caseId: this.currentCase._id
      }
      this.$refs.AddCaseModel.addCaseModelDialog = true
    },
    openCaseTimeline () {
      this.$refs.CaseTimeline.caseTimeLineDialog = true
    },
    getTranslatedStatus (text) {
      if (text === 'To do') return this.$t('cases|to_do_cases')
      if (text === 'In progress') return this.$t('cases|in_progress')
      if (text === 'Done') return this.$t('cases|finished_cases')
    },
    async exportCase (currentCase) {
      this.loading = true
      await this.createZip()
      this.loading = false
    },
    async downloadDocument (path) {
      const { data } = await this.$axios({
        url: path,
        method: 'GET',
        responseType: 'blob' // important
      })
      return data
    },
    async createZip () {
      let zipNameInput = this.currentCase.caseName
      let zip = new JSZip()
      // let workbenches = zip.folder(`workbenches`)
      let docs = zip.folder(`${this.currentCase.caseName} documents`)
      for (let i = 0; i < this.currentCase.documents.length; i++) {
        // we need to remove .files/ from path. .files is available in backend
        const slicedPath = this.currentCase.documents[i].raw.path.slice(6, this.currentCase.documents[i].raw.path.length)
        const path = `/downloadDocument/${slicedPath}`
        const file = await this.downloadDocument(path)
        docs.file(this.currentCase.documents[i].raw.originalname, new Blob([file]))
      }
      for (let index = 0; index < this.currentCase.workbenches.length; index++) {
        const wb = this.currentCase.workbenches[index]
        let workbenchFolder = zip.folder(`${wb.name}`)
        for (let idx = 0; idx < wb.assignedDocuments.length; idx++) {
          const doc = wb.assignedDocuments[idx]
          const slicedPath = doc.raw.path.slice(6, doc.raw.path.length)
          const path = `/downloadDocument/${slicedPath}`
          const wbFile = await this.downloadDocument(path)
          workbenchFolder.file(doc.raw.originalname, new Blob([wbFile]))
        }
        for (let i = 0; i < wb.templates.length; i++) {
          const template = wb.templates[i]
          const json = template.templateData.json
          this.$refs.Editor.editor.commands.setContent(json)
          const doc = this.$refs.Editor.editor.view.state.doc
          const type = 'docx'
          const templateName = template.name + '.docx'
          const caseExport = true
          const exported = await exportTemplate(doc, templateName, type, caseExport)
          workbenchFolder.file(template.name, exported)
        }
      }
      this.$refs.Editor.editor.destroy()
      zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, zipNameInput)
        }).catch((err) => {
          console.error('createZip', err)
        })
    },
    async addNewClient (newClient) {
      let clientName
      if (newClient.clientType === 'individual') {
        clientName = newClient.clientData.given_names + ' ' + newClient.clientData.surname
      }
      if (newClient.clientType === 'company') {
        clientName = newClient.clientData.company_name
      }
      const _id = this.currentCase._id
      const payload = {
        newClientId: newClient._id,
        clientName: clientName
      }
      try {
        await this.updateCaseClient({ _id, payload })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.workbenches-panel {
  &.is-expanded {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 15;
  }
}
.input:focus {
  outline: none !important;
  border:1px solid #234e79;
}
.title {
  text-align: left;
  font: normal normal normal 22px/27px Roboto;
  letter-spacing: 0px;
  color: #4B5056;
  opacity: 1;
  margin-left: 15px;
  margin-top: 10px;
}
.close-icon-position {
  position: absolute;
  top: 8px;
  right: 12px;
}
.pin-position {
  position: absolute;
  top: 50px;
  right: 5px;
}
.status-menu-position {
  position: absolute;
  top: 50px;
  right: 35px;
}
.icon-position {
  position: relative;
  left: 12rem;
  bottom: 1.5rem;
}
.icon-case-action-position {
  position: absolute;
  left: 3px;
}
.questionairre-position {
  position: absolute;
  top: 80px;
  right:10px;
}
.tabs-text {
  text-transform:none !important;
}
</style>
