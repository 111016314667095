<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      right
      :width="vuetifyWidth >= 1080 ? vuetifyWidth / 1.4 : vuetifyWidth / 1.3"
      fixed
      class="mb-2 rounded-l-lg"
      :style="`height: ${vuetifyHeight}; position: fixed; top: 50px; z-index: 100`"
    >
      <!-- v-navidation-drawer =>  add "temporary" prop for closing on click outside if needed -->
      <CaseOverview
        v-if="caseData"
        :current-case="currentCase"
        :workbench-height="workbenchHeight"
        @close-drawer="closeDrawer"
      />
      <CaseModelOverview
        v-if="caseForm"
        :current-case-form="currentCaseForm"
        :workbench-height="workbenchHeight"
        :lavvira-case-form="lavviraCaseForm"
        @close-drawer="closeDrawer"
      />
      <ClientOverview
        v-if="client"
        :actual-client="client"
        @close-drawer="closeDrawer"
      />
      <TemplateHistoryOverview
        v-if="templateHistoryOverview && (inTemplate || inCase || inCaseModel)"
        :in-template="inTemplate"
        :in-case="inCase"
        :in-case-model="inCaseModel"
        :template="template"
        :actual-case="actualCase"
        :actual-case-model="actualCaseModel"
        :workbench="workbench"
        @close-drawer="closeDrawer('from-template-history')"
      />
      <TemplateOverview
        v-if="template && !templateHistoryOverview"
        :template="template"
        @close-drawer="closeDrawer"
      />
    </v-navigation-drawer>
    <v-overlay
      z-index="0"
      :value="overlay"
      color="blue-grey darken-4"
    />
  </div>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { mapState } from 'vuex'
import axios from '@/plugins/axios'
import ClientOverview from './ClientOverview.vue'
import CaseOverview from './CaseOverview.vue'
import TemplateHistoryOverview from './TemplateHistoryOverview.vue'
import CaseModelOverview from './CaseModelOverview.vue'
import TemplateOverview from './TemplateOverview.vue'

export default {
  components: { CaseOverview, ClientOverview, TemplateHistoryOverview, CaseModelOverview, TemplateOverview },
  data () {
    return {
      drawer: false,
      items: [
        { title: 'Home', icon: 'mdi-view-dashboard' },
        { title: 'About', icon: 'mdi-forum' }
      ],
      caseData: null,
      caseForm: null,
      client: null,
      overlay: false,
      zIndex: 0,
      lavviraCaseForm: false,
      template: null,
      workbench: null,
      actualCase: null,
      actualCaseModel: null,
      inTemplate: false,
      inCase: false,
      inCaseModel: false,
      templateHistoryOverview: false
    }
  },
  computed: {
    ...mapState({
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      caseForms: state => state.caseForms.caseForms,
      companyCaseForms: state => state.companyCaseForms.companyCaseForms,
      groupCaseForms: state => state.groupCaseForms.groupCaseForms,
      lavviraCaseForms: state => state.caseForms.lavviraCaseForms
    }),
    currentCase () {
      let _currentCase = {}
      _currentCase = this.cases.find(c => c._id === this.caseData._id) || this.companyCases.find(c => c._id === this.caseData._id) || this.groupCases.find(c => c._id === this.caseData._id)
      return _currentCase
    },
    currentCaseForm () {
      let _currentCaseForm = {}
      _currentCaseForm = this.caseForms.find(cf => cf._id === this.caseForm._id) ||
      this.companyCaseForms.find(cf => cf._id === this.caseForm._id) || this.groupCaseForms.find(cf => cf._id === this.caseForm._id) ||
      this.lavviraCaseForms.find(cf => cf._id === this.caseForm._id)
      return _currentCaseForm
    },
    vuetifyWidth () {
      return this.$vuetify.breakpoint.width
    },
    vuetifyHeight () {
      return this.$vuetify.breakpoint.height - 60 + 'px'
    },
    workbenchHeight () {
      return (this.$vuetify.breakpoint.height - 60).toString()
    }
  },
  watch: {
    drawer (value) {
      if (!value) {
        this.caseData = null
        this.caseForm = null
        this.client = null
        this.drawer = false
        this.overlay = false
        this.lavviraCaseForm = false
        this.template = null
        this.workbench = null
        this.actualCase = null
        this.inTemplate = false
        this.inCase = false
        this.inCaseModel = false
      }
    }
  },
  created () {
    EventBus.$on('drawer-overview', this.onDrawerOverview)
    EventBus.$on('drawer-overview-case', this.onCaseDrawerOverview)
    EventBus.$on('drawer-overview-case-model', this.onCaseModelDrawerOverview)
    EventBus.$on('drawer-overview-client', this.onClientDrawerOverview)
    EventBus.$on('drawer-overview-template', this.onTemplateDrawerOverview)
    EventBus.$on('drawer-overview-template-data-history', this.onTemplateHistoryOverview)
    EventBus.$on('drawer-overview-case-template-data-history', this.onCaseTemplateHistoryOverview)
    EventBus.$on('drawer-overview-model-template-data-history', this.onCaseModelTemplateHistoryOverview)
  },
  beforeDestroy () {
    EventBus.$off('drawer-overview', this.onDrawerOverview)
    EventBus.$off('drawer-overview-case', this.onCaseDrawerOverview)
    EventBus.$off('drawer-overview-case-model', this.onCaseModelDrawerOverview)
    EventBus.$off('drawer-overview-client', this.onClientDrawerOverview)
    EventBus.$off('drawer-overview-template', this.onTemplateDrawerOverview)
    EventBus.$off('drawer-overview-template-data-history', this.onTemplateHistoryOverview)
    EventBus.$off('drawer-overview-case-template-data-history', this.onCaseTemplateHistoryOverview)
    EventBus.$off('drawer-overview-model-template-data-history', this.onCaseModelTemplateHistoryOverview)
  },
  methods: {
    onDrawerOverview () {
      this.drawer = true
    },
    onCaseDrawerOverview (caseData) {
      this.caseData = caseData
      this.overlay = true
      this.drawer = true
    },
    onCaseModelDrawerOverview (caseForm, contentType) {
      if (contentType && contentType === 'lavvira-content') {
        this.lavviraCaseForm = true
      }
      this.caseForm = caseForm
      this.overlay = true
      this.drawer = true
    },
    onClientDrawerOverview (client) {
      this.client = client
      this.overlay = true
      this.drawer = true
    },
    async onTemplateDrawerOverview (_id) {
      const { data } = await axios.get(`/getTemplate/${_id}`)
      this.template = data.data
      this.overlay = true
      this.drawer = true
    },
    async onTemplateHistoryOverview (_id) {
      const { data } = await axios.get(`/getTemplate/${_id}`)
      this.template = data.data
      this.inTemplate = true
      this.overlay = true
      this.drawer = true
    },
    async onCaseTemplateHistoryOverview (caseId, workbenchId, templateID) {
      const { data } = await axios.get(`/getCase/${caseId}`)
      this.actualCase = data.data
      this.workbench = data.data.workbenches.find(wb => wb._id === workbenchId)
      this.template = this.workbench.templates.find(t => t._id === templateID)
      this.inCase = true
      this.templateHistoryOverview = true
      this.overlay = true
      this.drawer = true
    },
    async onCaseModelTemplateHistoryOverview (caseModelId, workbenchId, templateID) {
      const { data } = await axios.get(`/getCaseForm/${caseModelId}`)
      this.actualCaseModel = data.data
      this.workbench = data.data.workbenches.find(wb => wb._id === workbenchId)
      this.template = this.workbench.templates.find(t => t._id === templateID)
      this.inCaseModel = true
      this.overlay = true
      this.drawer = true
    },
    closeDrawer (input) {
      this.caseData = null
      this.caseForm = null
      this.client = null
      this.drawer = false
      this.overlay = false
      this.lavviraCaseForm = false
      this.template = null
      this.workbench = null
      this.actualCase = null
      this.inTemplate = false
      this.inCase = false
      this.inCaseModel = false
      this.templateHistoryOverview = false
      if (input && input === 'from-template-history') {
        EventBus.$emit('focus-editor')
      }
    },
    percentage (percent, total) {
      return ((percent / 100) * total).toFixed(2)
    }
  }
}
</script>
