<template>
  <v-container
    fluid
  >
    <v-row
      dense
    >
      <v-col cols="9">
        <v-card
          flat
          class="mt-n5"
        >
          <v-card-title>
            {{ $t('clients|client_overview') }}
          </v-card-title>
          <v-card-title class="mt-n5">
            {{ clientName }}
          </v-card-title>
          <v-card-text
            class="d-flex mt-n8"
            style="min-height: 20px"
          >
            <v-col
              cols="8"
            >
              <div v-if="client.clientType==='company'">
                <div>
                  {{ $t('clients|company_type') }}: {{ client.clientData.company_type }}
                </div>
                <div>
                  {{ $t('clients|share_capital') }}: {{ client.clientData.selected_currency ? client.clientData.selected_currency.symbol : '' }} {{ client.clientData.shared_capital }}
                </div>
                <div>
                  {{ $t('clients|registration_number') }}: {{ client.clientData.rcs_number }}
                </div>
                <div>
                  {{ $t('clients|date_of_registration') }}: {{ client.clientData.date_of_registration }}
                </div>
                <div>
                  {{ $t('clients|company_registration_place') }}: {{ client.clientData.place_of_registration }}
                </div>
                <div>
                  {{ $t('clients|address') }}: {{ client.clientData.address }}
                </div>
                <div>
                  {{ $t('clients|city') }}: {{ client.clientData.city }}
                </div>
                <div>
                  {{ $t('clients|postal_code') }}: {{ client.clientData.postal_code }}
                </div>
                <div>
                  {{ $t('common|email') }}: {{ client.clientData.email }}
                </div>
                <div>
                  {{ $t('clients|company_representative') }}: {{ client.clientData.company_representative_name }}
                </div>
                <div>
                  {{ $t('clients|company_representative') }}  {{ $t('common|email') }}: {{ client.clientData.company_representative_email }}
                </div>
              </div>

              <!-- Individual client type -->
              <div v-else>
                <div>
                  {{ $t('clients|date_of_birth') }}: {{ client.clientData.date_of_birth }}
                </div>
                <div>
                  {{ $t('clients|nationality') }}: {{ client.clientData.nationality }}
                </div>
                <div>
                  {{ $t('clients|place_of_birth') }}: {{ client.clientData.place_of_birth }}
                </div>
                <div>
                  {{ $t('clients|address') }}: {{ client.clientData.address }}
                </div>
                <div>
                  {{ $t('clients|city') }}: {{ client.clientData.city }}
                </div>
                <div>
                  {{ $t('clients|postal_code') }}: {{ client.clientData.postal_code }}
                </div>
                <div>
                  {{ $t('common|email') }}: {{ client.clientData.email }}
                </div>
              </div>
            </v-col>
            <v-col
              cols="4"
            >
              <div class="d-flex flex-column mt-n3">
                <v-btn
                  color="primary"
                  rounded
                  small
                  @click="openEditDialog"
                >
                  <v-icon
                    left
                    small
                    class="icon-position"
                  >
                    mdi-eye
                  </v-icon>
                  <span
                    class="ml-5"
                  >
                    {{ client.realClient ? $t('profile|profile_overview') : $t('profile|edit_profile') }}
                  </span>
                </v-btn>
                <v-btn
                  color="primary"
                  rounded
                  small
                  class="mt-3"
                  @click="openExportDialog"
                >
                  <v-icon
                    small
                    left
                    class="icon-position"
                  >
                    mdi-export
                  </v-icon>
                  {{ $t('documents|export_documents') }}
                </v-btn>
              </div>
            </v-col>
          </v-card-text>
        </v-card>
        <v-card
          flat
          outlined
          :height="client.clientType === 'company' ? ($vuetify.breakpoint.height - 530).toString() : ($vuetify.breakpoint.height - 430).toString()"
          style="overflow-y: auto"
        >
          <v-toolbar
            flat
            dense
            color="lightBackground"
            style="position: sticky; top: 0px; z-index: 1"
          >
            <v-card-title>
              {{ $t('clients|client_cases') }}
            </v-card-title>
          </v-toolbar>
          <v-card-text
            v-if="clientCases && clientCases.length"
          >
            <v-row class="px-2">
              <div
                v-for="c in clientCases"
                :key="c._id"
                class="mx-1 my-2"
              >
                <CaseCardMini
                  :key="c._id"
                  :case-data="c"
                  :from-client-section="true"
                />
              </div>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            {{ $t('cases|no_cases_available') }}
          </v-card-text>
        </v-card>
        <v-dialog
          v-model="companyDialog"
          width="600"
        >
          <CompanyClientForm
            v-if="client && client.clientType === 'company'"
            :client="client"
            :has-back-button="false"
            @submit="submit"
            @close="companyDialog = false"
          />
        </v-dialog>
        <v-dialog
          v-model="individualDialog"
          max-width="600"
        >
          <v-container
            fill-height
            fluid
            class="pa-0"
            style="background-color: white;"
          >
            <IndividualClientForm
              v-if="client && client.clientType === 'individual'"
              :client="client"
              :has-back-button="false"
              class="pa-2"
              style="width: 100%;"
              @submit="submit"
              @close="individualDialog = false"
            />
          </v-container>
        </v-dialog>
        <FileView ref="fileView" />
      </v-col>
      <v-col cols="3">
        <v-icon
          size="20"
          color="primary"
          class="close-icon-position"
          @click="$emit('close-drawer')"
        >
          mdi-arrow-collapse-right
        </v-icon>
        <v-card
          flat
          outlined
          class="mt-10"
          :height="($vuetify.breakpoint.height - 193).toString()"
          style="overflow-y: auto;"
        >
          <v-toolbar
            flat
            dense
            color="lightBackground"
            style="position: sticky; top: 0px; z-index: 1"
          >
            <div
              class="justify-center"
            >
              {{ $t('clients|client_documents') }}
            </div>
          </v-toolbar>
          <div
            class="pa-3"
            style="background-color: white; position: sticky; top: 48px; z-index: 1"
          >
            <v-text-field
              v-model="searchDocument"
              flat
              prepend-inner-icon="mdi-magnify"
              :label="$t('actions|search_clients_documents')"
              rounded
              outlined
              hide-details
              dense
              class="shrink mx-3"
            />
          </div>
          <v-card-text
            :key="componentKey"
          >
            <div v-if="filteredDocumentsByClient && filteredDocumentsByClient.length">
              <template
                v-for="doc in filteredDocumentsByClient"
              >
                <ClientDocumentItem
                  :key="doc._id"
                  :document="doc"
                  class="my-4"
                  @open-doc="openDocument(doc)"
                  @deleteAction="unassignDocFromClient(doc)"
                />
              </template>
            </div>
            <div
              v-else
              class="center"
            >
              {{ $t('expressions|no_available_documents') }}
            </div>
          </v-card-text>
          <v-card-actions
            class="mx-0"
            style="background-color: white; position: sticky; bottom: 0px; z-index: 1"
          >
            <v-btn
              rounded
              small
              color="primary"
              class="mx-auto"
              @click="uploadDocument()"
            >
              <v-icon
                small
                left
              >
                mdi-upload
              </v-icon>
              {{ $t('actions|upload_documents') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card-actions class="mt-4 ml-4">
      <v-btn
        outlined
        rounded
        small
        color="primary"
        link
        :to="`/clients/${client._id}`"
        @click="$emit('close-drawer')"
      >
        <v-icon
          color="primary"
          small
          class="mr-1"
        >
          mdi-open-in-new
        </v-icon>
        {{ $t('actions|open') }}
      </v-btn>
      <v-spacer />
      <v-btn
        outlined
        rounded
        small
        color="primary"
        class=""
        @click.stop="deleteClient(client)"
      >
        <v-icon
          color="primary"
          small
          class="mr-1"
        >
          mdi-delete
        </v-icon>
        {{ $t('actions|delete_client') }}
      </v-btn>
    </v-card-actions>
    <FileView
      ref="fileView"
    />
  </v-container>
</template>

<script>
import clientMixin from '../../pages/Clients/ClientMixins/clientMixin'
import generalMixin from '@/utils/generalMixin.js'
import ClientActions from '../../pages/Clients/ClientMixins/ClientActions'
import ClientDocumentItem from '../../pages/Clients/ClientDocumentItem.vue'
import FileView from '@/components/dialogs/FileView.vue'

export default {
  components: {
    ClientDocumentItem,
    FileView
  },
  mixins: [generalMixin, ClientActions, clientMixin],
  props: {
    actualClient: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>

.close-icon-position {
  position: absolute;
  top: 15px;
  right: 12px;
}
.icon-position {
  position: absolute;
  left: 3px;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
